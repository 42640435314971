<section fxLayout="column" class="page-container">

  <!-- HEADER -->
  <app-header [pageName]="'FAQ'"></app-header>

  <!-- CONTENT  -->
  <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="content-container">
    <div fxLayout="column" fxLayoutAlign="stretch center" class="content-wrapper"
      [ngClass]="{'w-100':mobileQuery.matches,'w-75':!mobileQuery.matches}">

      <div fxFlex fxLayout="column" fxLayoutAlign="center center">

        <mat-card [class]="!mobileQuery.matches?'mat-elevation-z0 w-80':''">
          <!-- <div>what_is_scores</div>
          <div>when_to_use_scores</div>
          <div>who_is_using_scores</div>
          <div>what_is_wrong_with_prioritizing_using_cvss</div>
          <div>what_is_wrong_with_prioritizing_using_epss</div>
          <div>how_does_scores_work</div>
          <div>where_can_i_learn_more_about_the_nuances_between_vulnerabilities_and_threats_and_risk</div>
          <div>what_are_the_information_sources_used</div> -->

          <h1 class="ml-1">Frequently Asked Questions </h1>

          <ul>
            <li>
              <h2 id="what_is_scores">What is SCORES ?</h2>
              <p>Seconize Contextual Risk Enumeration System(SCORES) is a simple and effective way of prioritizing
                vulnerabilities and misconfigurations alike.</p>
            </li>

            <li>
              <h2 id="when_to_use_scores">When to use SCORES ?</h2>
              <p>Imagine you have ended up with thousands of issues identified while running vulnerability
                assessments and
                penetration testing tools on your IT infrastructure. Often remediating them is a daunting task.
                SCORES will help
                you prioritize these vulnerabilities using proven decision science algorithms.
                So each time you hear a new CVE (log4j, follina and others) don't panic. Keep calm and SCORE.
              </p>
            </li>

            <li>
              <h2 id="who_is_using_scores">Who is using SCORES ?</h2>
              <p>SCOREs is developed by Risk-Score. So far it has been used in 1000+ assessments and prioritizing
                1000000+
                vulnerabilities identified. It is used by Organizations in all verticals like IT, ITES, EdTech,
                FinTech, Pharma,
                Healthcare, IOT among many others.</p>
            </li>

            <li>
              <h2 id="what_is_wrong_with_prioritizing_using_cvss">What is wrong with prioritizing using CVSS ?</h2>
              <p> <a href="https://www.first.org/cvss/" target="_blank">Common Vulnerability Scoring System(CVSS) </a>
                is
                predominantly static and does not consider your
                Organization's
                context and Asset's context on which vulnerabilities are identified. It is a vulnerability scoring
                system but
                not a risk scoring system. Mere existince of vulnerability is not a risk on its own.
                Often prioritizing of vulnerabilities involves lot of contextual parameters and making tough
                decisions.

                For example, A CVSSv3 score of 9.8 considered highly critical but found on a Windows Server that
                does have minimal customer data versus CVSSv3 score of 7 considered moderate severity but found on an
                Database
                server that
                contains most of your customer data. What if one CVE has a known exploit and other does not have?
                What if
                Windows Server is facing the Internet, where as Database server is behind a VPN ?

                Also, CVSS is not applicable for application security vulnerabilities and misconfigurations like OWASP
                Top Ten or CIS Benchmarks.</p>
            </li>

            <li>
              <h2 id="what_is_wrong_with_prioritizing_using_epss">What is wrong with prioritizing using EPSS ?</h2>
              <p> <a href="https://www.first.org/epss/" target="_blank">Exploit Prediction Scoring System(EPSS) </a> is
                the
                latest standard by first.org predominantly to predict
                whether an
                exploit is likely to be developed in near future. It is only of many parameters that are needed to
                prioritize
                vulnerabilities.
              </p>
            </li>

            <li class="mb-1">
              <h2 id="how_does_scores_work">So how does SCORES work ?</h2>
              <p>SCORES is based on simple foundational principles that are widely accepted by risk community in
                general i.e
                risk is function of Likelihood of threat and impact.
                A risk score is computed for each vulnerability using numerous factors. High level overview is as
                given
                below:
              </p>
              <ol>
                <li>Each vulnerability is contextualized using threat intelligence to identify the availability of
                  an
                  exploit,
                  active malware campaigns, industries and geographies impacted.</li>
                <li>Asset's Susceptibility to a cyber attack is considered based on whether it is reachable via
                  Internet or
                  behind any existing security controls</li>
                <li>Likelihood of threat is subsequently computation based on both enriched vulnerability parameters
                  and assets
                  susceptibility</li>
                <li>Impact Factor is derived based on how important is that asset in your infrastructure.
                  Essentially,
                  each asset
                  is ranked.</li>
                <li> Risk Score is now computed based on both Likelihood of Threat and Impact.
                  All these computations are made based on proven decision science algorithms called <a
                    href="https://en.wikipedia.org/wiki/Analytic_hierarchy_process" target="_blank">Analytic
                    Hierarchy Process</a>
                  .</li>
              </ol>
            </li>

            <li>
              <h2 id="where_can_i_learn_more_about_the_nuances_between_vulnerabilities_and_threats_and_risk">Where can I
                learn more about the nuances between vulnerabilities, threats and risk ?</h2>
              <p>You can learn more about the terminology, definitions and examples
                <a href="https://www.youtube.com/watch?v=SzFSQqQNYCM" target="_blank">here</a>.
              </p>
            </li>

            <li>
              <h2 id="what_are_the_information_sources_used">What are the information sources used ?</h2>
              <p> <a href="https://nvd.nist.gov/" target="_blank">National Vulnerability Database (NVD) </a>,
                <a href="https://otx.alienvault.com/" target="_blank">AlienVault OTX </a> ,
                <a href="https://www.exploit-db.com/" target="_blank">ExploitDB</a> ,
                <a href="https://vuls.io/" target="_blank">Vuls </a>
              </p>
            </li>
          </ul>

          <div fxLayoutAlign="center">
            <span>If you have further questions, suggestions, feedback then feel free to reach us at&nbsp;
              <a href="mailto:scores@seconize.co">scores@seconize.co</a>
            </span>
          </div>

        </mat-card>

      </div>
    </div>
  </div>

  <!-- FOOTER  -->
  <app-footer></app-footer>

</section>