<div fxLayout="column" [fxLayoutAlign]="mobileQuery.matches?'start start':'center center'"
    [ngClass]="{'bc-w':mobileQuery.matches || smallMobileQuery.matches || smallerMobileQuery.matches }">

    <div fxLayout="column" fxLayoutAlign="center center" [ngClass]="{
        'w-74':smallerMobileQuery.matches,
        'w-80':smallMobileQuery.matches,
        'w-86':mobileQuery.matches,
        'w-100':mobileQuery.matches,
        'w-75':!mobileQuery.matches}">

        <mat-toolbar style="background-color: white">

            <button mat-button *ngIf="mobileQuery.matches" [matMenuTriggerFor]="menu">
                <mat-icon aria-label="Side nav toggle icon" class="menuIcon">menu
                </mat-icon>
            </button>

            <mat-menu #menu="matMenu">
                <div mat-menu-item *ngFor="let item of navItems" [class]="{'isActive': pageName == item.name}">
                    <a mat-button color="primary" [href]="item.route">{{item.name}}</a>
                </div>
            </mat-menu>

            <div [fxLayoutAlign]="mobileQuery.matches?'center center':'start start'" fxFlex=90>

                <div fxLayout="row" fxLayoutAlign="space-between center" routerLink="/">
                    <img class="logo mr-1" src="assets/logo1.JPG" alt="">
                    <div class="verticalDivider"></div>
                    <div class="title ml-1">
                        SCORES
                    </div>
                </div>

            </div>

            <span class="mr-1" fxLayoutAlign="start end" *ngIf="!mobileQuery.matches">

                <span *ngFor="let item of navItems">
                    <a mat-button color="primary" [href]="item.route"
                        [class]="{'isActive': pageName == item.name}">{{item.name}}</a>
                </span>
            </span>

        </mat-toolbar>

    </div>
</div>