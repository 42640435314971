import { MediaMatcher } from '@angular/cdk/layout';
import { ViewportScroller } from '@angular/common';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  mobileQuery: MediaQueryList;
  smallMobileQuery: MediaQueryList
  smallerMobileQuery: MediaQueryList
  handsetQuery: MediaQueryList
  smallestMobileQuery: MediaQueryList
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private scroller: ViewportScroller,
    private activatedRoute: ActivatedRoute,
  ) {
    //handset
    this.handsetQuery = media.matchMedia('(max-width: 1100px)');
    this._handsetQueryListener = () => changeDetectorRef.detectChanges();
    this.handsetQuery.addListener(this._handsetQueryListener);
    //mobile
    this.mobileQuery = media.matchMedia('(max-width: 900px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    //small screen mobile
    this.smallMobileQuery = media.matchMedia('(max-width: 799px)');

    this._smallMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallMobileQuery.addListener(this._smallMobileQueryListener);
    //small screen mobile
    this.smallerMobileQuery = media.matchMedia('(max-width: 590px)');
    this._smallerMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallerMobileQuery.addListener(this._smallerMobileQueryListener);
    //smallest screen mobile
    this.smallestMobileQuery = media.matchMedia('(max-width: 480px)');
    this._smallestMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallestMobileQuery.addListener(this._smallestMobileQueryListener);
  }

  private _mobileQueryListener: () => void;
  private _smallMobileQueryListener: () => void;
  private _smallerMobileQueryListener: () => void;
  private _handsetQueryListener: () => void;
  private _smallestMobileQueryListener: () => void;
  ngOnInit(): void {
    this.question_ID = this.activatedRoute.snapshot.paramMap.get('id');

    const question = document.getElementById(this.question_ID);

    if (question != null) {
      question.style.backgroundColor = 'yellow';
    }

    this.scroller.scrollToAnchor(this.question_ID);
  }
  question_ID: any

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.smallMobileQuery.removeListener(this._smallMobileQueryListener);
    this.smallerMobileQuery.removeListener(this._smallerMobileQueryListener);
    this.smallestMobileQuery.removeListener(this._smallerMobileQueryListener);
    this.handsetQuery.removeListener(this._handsetQueryListener);

  }
}
