<!-- <div class="spinner-wrapper" *ngIf="showLoader">
    <mat-spinner class="spinner"></mat-spinner>
</div> -->

<section fxLayout="column" class="page-container">

    <!-- HEADER -->
    <app-header [pageName]="'Home'"></app-header>

    <!-- CONTENT  -->
    <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="content-container">
        <div fxLayout="column" fxLayoutAlign="stretch center" class="content-wrapper"
            [ngClass]="{'w-100':mobileQuery.matches,'w-75':!mobileQuery.matches}">

            <div class="mb-0 mt-2" fxLayoutAlign="center center">
                <span class="mb-3 w-80">
                    <h1>What is SCORES?</h1>
                    <p>
                        SCORES (Seconize Contextual Risk Enumeration System)
                        is a free risk scoring tool for vulnerabilities.
                    </p>

                    <p>
                        Contemporary vulnerability scoring standards like
                        <a href="https://www.first.org/cvss/" target="_blank"> CVSS
                        </a>
                        and
                        <a href="https://www.first.org/epss/" target="_blank"> EPSS
                        </a>
                        lack ability to be contextualized for a given organization. They do not consider contextual
                        parameters of the IT asset on which vulnerability is identified or the nature of organization's
                        business. Any remediation efforts that rely on these standardized scores alone may be
                        counter-productive to overall risk reduction.
                    </p>

                    <p>
                        You can create
                        personalized risk scores for vulnerabilities based on your Organization and Asset
                        context using proven decision science techniques. SCORES helps you to contextualize
                        vulnerabilities to your environment,
                        so keep calm and SCORE !
                    </p>

                </span>
            </div>

            <div fxLayoutAlign="center center" class="mt-2" *ngIf="!showStepper">

                <div class="search-input">
                    <input type="text" [formControl]="cveID" class="cveId-input" id="cveId-input" name="cveId-input"
                        (keyup.enter)="getCVEdata()" placeholder=" " (keyup)='checkCVEId($event)' />
                    <label for="cveId-input">Enter CVE ID</label>
                </div>
            </div>
            <div fxLayoutAlign="center center" class="nodata" *ngIf="nodata || invalidCVE">
                {{nodata?'CVE not found , please check later':'Invalid CVE ID'}}
            </div>

            <div fxLayoutAlign="center center" class="mb-1" *ngIf="!showStepper">
                <button (click)='getCVEdata()' mat-flat-button class="ml-1 seconizeButton get-your-score">
                    {{isCVE_data_Loading?'Checking...':'Know Your SCORE'}}
                </button>
            </div>
            <div fxLayoutAlign="center center" class="w-80">
                <span *ngIf="!showStepper && recentFiveVulns.length>=1" class="CVE-list mt-1">
                    Most Recents :
                    <span (click)="CVE_selected(cve.cveID)" [matTooltip]="tooltip(cve.dateAdded,cve.vendorProject)"
                        *ngFor="let cve of recentFiveVulns">{{cve.cveID.replaceAll('"', '')}},</span>
                </span>
            </div>


            <!-- CREATE REPORT  -->
            <mat-card *ngIf="showStepper" class="seconizeShadow w-80" id="stepper">

                <h3 *ngIf="!showRiskTab" class="cardTitle">Create Your Risk Report
                    <button class="close-button" mat-icon-button (click)="reset_CVE_ID()">
                        <mat-icon class="close-icon">close</mat-icon>
                    </button>
                </h3>

                <mat-stepper *ngIf="!showRiskTab" labelPosition="bottom" #stepper>

                    <ng-template matStepperIcon="edit">
                        <mat-icon>done</mat-icon>
                    </ng-template>

                    <!------------ FIRST STEP [VULNERABILITY] ------------>
                    <mat-step>
                        <form fxLayout="column" fxLayoutGap="15px">

                            <ng-template matStepLabel>Vulnerability</ng-template>

                            <div class="mt-1" fxLayout="column" fxLayoutGap="7px">
                                <!-- CVE ID -->
                                <div fxLayoutGap="7px">
                                    <small [fxFlex]="mobileQuery.matches?'29':20"><b>CVE ID:</b></small>
                                    <div fxFlex>
                                        <div>
                                            {{ staticCveID }}
                                        </div>
                                    </div>
                                </div>

                                <!-- Description -->
                                <div>
                                    <small [fxFlex]="mobileQuery.matches?'29':20"><b>Description:</b></small>
                                    <div fxFlex>
                                        <div>
                                            {{ cveData?.description }}
                                        </div>
                                    </div>
                                </div>

                                <!-- CVSS v2 -->
                                <div *ngIf="!mobileQuery.matches">
                                    <small fxFlex="20"><b>CVSS v2:</b></small>
                                    <div fxFlex>
                                        <div>

                                            <b fxFlex="10">{{ cveData?.cvssV2Score!=0 &&
                                                cveData?.cvssV2Score!=null?cveData?.cvssV2Score:'--' }}</b>
                                            <b class="ml-1">Vector:&nbsp;</b>
                                            {{ cveData?.cvssV2Vector!=null?cveData?.cvssV2Vector:'--' }}
                                            <a *ngIf="cveData?.cvssV2Vector!=null" style="height: 1px"
                                                href="https://www.first.org/cvss/v2/guide" target="_blank">
                                                <mat-icon style="transform:scale(0.8)" class="ml-1"
                                                    matTooltip="https://www.first.org/cvss/v2/guide">
                                                    info_outline
                                                </mat-icon>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="mobileQuery.matches">
                                    <small fxFlex="29"><b>CVSS v2:</b></small>
                                    <div fxFlex>
                                        <div>

                                            <b>{{ cveData?.cvssV2Score!=0 &&
                                                cveData?.cvssV2Score!=null?cveData?.cvssV2Score:'--' }}</b>

                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="mobileQuery.matches">
                                    <small fxFlex="29"><b>Vector:</b></small>
                                    <div fxFlex>
                                        <div class="wb-ba" fxFlex="fit-content">
                                            {{ cveData?.cvssV2Vector!=null?cveData?.cvssV2Vector:'--' }}
                                            <a *ngIf="cveData?.cvssV2Vector!=null" style="height: 1px"
                                                href="https://www.first.org/cvss/v2/guide" target="_blank">
                                                <mat-icon style="transform:scale(0.8)" class="ml-1"
                                                    matTooltip="https://www.first.org/cvss/v2/guide">
                                                    info_outline
                                                </mat-icon>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <!-- CVSS v3 -->
                                <div *ngIf="!mobileQuery.matches">
                                    <small fxFlex="20"><b>CVSS v3:</b></small>
                                    <div fxFlex>
                                        <div>
                                            <b fxFlex="10">{{ cveData?.cvssV3Score!=0 &&
                                                cveData?.cvssV3Score!=null?cveData?.cvssV3Score:'--' }}</b>
                                            <b class="ml-1">Vector:&nbsp;</b>
                                            {{ cveData?.cvssV3Vector!=null?cveData?.cvssV3Vector:'--' }}
                                            <a *ngIf="cveData?.cvssV3Vector!=null" style="height: 1px"
                                                href="https://www.first.org/cvss/v3.0/specification-document"
                                                target="_blank">
                                                <mat-icon style="transform:scale(0.8)" class="ml-1"
                                                    matTooltip="https://www.first.org/cvss/v3.0/specification-document">
                                                    info_outline
                                                </mat-icon>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="mobileQuery.matches">
                                    <small fxFlex="29"><b>CVSS v3:</b></small>
                                    <div fxFlex>
                                        <div>
                                            <b>{{ cveData?.cvssV3Score!=0 &&
                                                cveData?.cvssV3Score!=null?cveData?.cvssV3Score:'--' }}</b>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="mobileQuery.matches">
                                    <small fxFlex="29"><b>Vector:&nbsp;</b></small>
                                    <div fxFlex>
                                        <div class="wb-ba" fxFlex="fit-content">
                                            <b>{{ cveData?.cvssV3Vector!=null?cveData?.cvssV3Vector:'--' }}
                                                <a *ngIf="cveData?.cvssV3Vector!=null" style="height: 1px"
                                                    href="https://www.first.org/cvss/v3.0/specification-document"
                                                    target="_blank">
                                                    <mat-icon style="transform:scale(0.8)" class="ml-1"
                                                        matTooltip="https://www.first.org/cvss/v3.0/specification-document">
                                                        info_outline
                                                    </mat-icon>
                                                </a></b>
                                        </div>
                                    </div>
                                </div>


                                <!-- Exploit -->
                                <div fxLayoutGap="7px">
                                    <small [fxFlex]="mobileQuery.matches?'29':20"><b>Exploit Available:</b></small>
                                    <mat-radio-group [formControl]="exploitCodeExists" color="primary" fxFlex
                                        aria-label="Select an option">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false" class="ml-1">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <!-- Malware -->
                                <div fxLayoutGap="7px">
                                    <small [fxFlex]="mobileQuery.matches?'29':20"><b>Malware Campaign:</b></small>
                                    <mat-radio-group [formControl]="malwareCampaignActive" color="primary" fxFlex
                                        aria-label="Select an option">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false" class="ml-1">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                            </div>

                            <div fxLayoutAlign="end">
                                <div
                                    [matTooltip]="cveData?.cvssV3Score===null && cveData?.cvssV2Score===null?'CVE under analysis, please try later':''">
                                    <button class="seconizeButton" mat-flat-button color="primary" matStepperNext
                                        [disabled]="cveData?.cvssV3Score===null && cveData?.cvssV2Score===null">Next</button>
                                </div>
                            </div>

                        </form>
                    </mat-step>

                    <!------------ SECOND STEP [ASSET] ------------>
                    <mat-step>
                        <form fxLayout="column" fxLayoutGap="15px">

                            <ng-template matStepLabel>Asset</ng-template>

                            <!-- Select an Asset in your Environment -->
                            <div class="mt-1" fxLayout="column" fxLayoutGap="7px">

                                <div fxLayoutAlign="space-between center">
                                    <small><b>Select an Asset in your Environment:</b></small>
                                </div>

                                <div class="cards">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Vendor</mat-label>
                                        <mat-select [formControl]="vendor"
                                            (selectionChange)="vendorChange(vendor.value)" required>
                                            <mat-option value="">Not Applicable</mat-option>
                                            <mat-option *ngFor="let item of cpeMap | keyvalue;let i = index"
                                                [value]="item.key">
                                                {{ getItem(item.key) | titlecase }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="vendor.value" appearance="outline">
                                        <mat-label>Product</mat-label>
                                        <mat-select [formControl]="product" required>
                                            <mat-option value="">Not Applicable</mat-option>
                                            <mat-option *ngFor="let item of cpeMap[vendor.value] | keyvalue"
                                                [value]="item.key">
                                                {{ getItem(item.key) | titlecase}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="!vendor.value" appearance="outline">
                                        <mat-label>Product</mat-label>
                                        <mat-select required>
                                            <mat-option>
                                                Select Vendor First
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="vendor.value && product.value" appearance="outline">
                                        <mat-label>Version</mat-label>
                                        <mat-select [formControl]="version" required>
                                            <mat-option value="">Not Applicable</mat-option>
                                            <mat-option
                                                *ngFor="let item of cpeMap[vendor.value][product.value] | keyvalue"
                                                [value]="item.key!='null'?item.key:'any'">
                                                <span *ngIf="item.key!='null'">
                                                    {{ getItem(item.key) | titlecase }}
                                                </span>
                                                <span *ngIf="item.key=='null'">
                                                    Any
                                                </span>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="!vendor.value || !product.value" appearance="outline">
                                        <mat-label>Version</mat-label>
                                        <mat-select required>
                                            <mat-option>
                                                Select Vendor and Product first
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                </div>

                            </div>

                            <div *ngIf="vendor.value && product.value  && version.value " class="mt-1" fxLayout="column"
                                fxLayoutGap="7px">

                                <!-- Asset Reachability -->
                                <div [fxLayout]="smallerMobileQuery?'row wrap':'row'">
                                    <small [fxFlex]="smallMobileQuery.matches?'fit-content':40" class="mr-7px"><b>Is the
                                            Asset Reachable from Internet?</b></small>
                                    <mat-radio-group [formControl]="assetReachability" color="primary"
                                        [fxFlex]="smallMobileQuery.matches?'fit-content':''"
                                        aria-label="Select an option">
                                        <mat-radio-button value="EXTERNAL">Yes</mat-radio-button>
                                        <mat-radio-button value="INTERNAL" class="ml-1">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <!-- Data Classification  -->
                                <div [fxLayoutAlign]="smallerMobileQuery?'start start':'space-between center'"
                                    fxLayoutGap="7px" [fxLayout]="smallerMobileQuery?'row wrap':'row'"
                                    [ngClass]="{'mb-10px':smallMobileQuery.matches}">
                                    <small [fxFlex]="smallMobileQuery.matches?'fit-content':40"><b>Classify the data
                                            type and Rank it:</b></small>
                                    <div fxFlex fxLayoutAlign="start center"
                                        [fxLayout]="smallMobileQuery.matches?'row wrap':'row'">

                                        <mat-form-field appearance="outline"
                                            [ngClass]="{'mb-20px':smallMobileQuery.matches}">
                                            <mat-label>Data</mat-label>
                                            <mat-select [formControl]="dataClass">
                                                <mat-option *ngFor="let item of dataAssetProfiles" [value]="item.name">
                                                    {{item.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>


                                        <!-- STARS  -->
                                        <div [formGroup]="assetProfileForm">
                                            <div formArrayName="dataClassification">
                                                <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap.gt-sm="15px">

                                                    <div
                                                        *ngFor="let item of dataClassification().controls; let i = index">
                                                        <div [formGroupName]="i">

                                                            <div fxLayout="row" fxLayout.lt-md="column"
                                                                fxLayoutGap.gt-sm="10px" fxLayoutAlign="center center">

                                                                <div>
                                                                    <mat-selection-list [multiple]="false"
                                                                        class="my-list-base">
                                                                        <mat-list-option
                                                                            *ngFor="let star of stars; let j = index"
                                                                            [value]="star.value" class="starIcon">
                                                                            <mat-icon
                                                                                (click)="$event.stopPropagation();profileStars1(assetProfileForm.controls.dataClassification.value[i].name,i,star)"
                                                                                [ngClass]="{selectedStarIcon:star.rating <= assetProfileForm.controls.dataClassification.value[i].rating}"
                                                                                [matTooltip]="tooltipData[j]">grade
                                                                            </mat-icon>
                                                                        </mat-list-option>
                                                                    </mat-selection-list>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <!-- Service Classification  -->
                                <div [fxLayoutAlign]="smallerMobileQuery?'start start':'space-between center'"
                                    [fxLayout]="smallerMobileQuery?'row wrap':'row'" fxLayoutGap="7px"
                                    [ngClass]="{'mb-10px':smallMobileQuery.matches}">
                                    <small [fxFlex]="smallMobileQuery.matches?'fit-content':40"><b>Classify the service
                                            type and Rank it:</b></small>
                                    <div fxFlex fxLayoutAlign="start center"
                                        [fxLayout]="smallMobileQuery.matches?'row wrap':'row'">
                                        <mat-form-field appearance="outline"
                                            [ngClass]="{'mb-20px':smallMobileQuery.matches}">
                                            <mat-label>Service</mat-label>
                                            <mat-select [formControl]="serviceClass">
                                                <mat-option *ngFor="let item of serviceAssetProfiles"
                                                    [value]="item.name">
                                                    {{item.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>


                                        <!-- STARS  -->
                                        <div [formGroup]="assetProfileForm">
                                            <div formArrayName="serviceClassification">
                                                <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap.gt-sm="10px">

                                                    <div
                                                        *ngFor="let item of serviceClassification().controls; let i = index">


                                                        <div [formGroupName]="i">

                                                            <div fxLayout="row" fxLayout.lt-md="column"
                                                                fxLayoutGap.gt-sm="10px" fxLayoutAlign="center center">

                                                                <div>
                                                                    <mat-selection-list [multiple]="false"
                                                                        class="my-list-base">
                                                                        <mat-list-option
                                                                            *ngFor="let star of stars; let j = index"
                                                                            [value]="star.value" class="starIcon">
                                                                            <mat-icon (click)="
                          $event.stopPropagation();
                          profileStars1(
                            assetProfileForm.controls.serviceClassification.value[i]
                            .name,
                            i,
                            star
                            )
                            " [ngClass]="{
                              selectedStarIcon:
                              star.rating <=
                              assetProfileForm.controls.serviceClassification.value[i]
                              .rating
                             
                            }" [matTooltip]="tooltipData[j]">grade</mat-icon>
                                                                        </mat-list-option>
                                                                    </mat-selection-list>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>



                            <div fxLayoutAlign="end">
                                <button class="seconizeButton" mat-stroked-button matStepperPrevious>Back</button>
                                <div
                                    [matTooltip]="!this.cpeList.length?'Applicable products not found , please try later':''">
                                    <button class="seconizeButton ml-1" mat-flat-button color="primary" matStepperNext
                                        [matTooltip]="enabledButton?'':'Please select all fields'"
                                        [disabled]="!enableStep2()">Next</button>
                                </div>
                            </div>

                        </form>
                    </mat-step>

                    <!------------ THIRD STEP [ORGANIZATION] ------------>
                    <mat-step [stepControl]="organizationFormGroup">
                        <form fxLayout="column" fxLayoutGap="15px" [formGroup]="organizationFormGroup">
                            <ng-template matStepLabel>Organization</ng-template>

                            <div class="mt-1" fxLayout="column" fxLayoutGap="7px">
                                <div [fxLayoutAlign]="smallerMobileQuery?'start start':'center'"
                                    [fxLayout]="smallerMobileQuery?'row wrap':'row'">
                                    <small [fxFlex]="smallerMobileQuery.matches?'fit-content':50"
                                        class="mr-7px"><b>Select your Industry type:</b></small>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Industry</mat-label>
                                        <mat-select formControlName="industry" required>
                                            <mat-option *ngFor="let industry of industryList" [value]="industry.value">
                                                {{industry.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div [fxLayoutAlign]="smallerMobileQuery?'start start':'center'"
                                    [fxLayout]="smallerMobileQuery?'row wrap':'row'">
                                    <small [fxFlex]="smallerMobileQuery.matches?'fit-content':50"
                                        class="mr-7px"><b>Select Countries Your Organization operates
                                            from:</b></small>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Countries</mat-label>
                                        <mat-select formControlName="country" multiple required>
                                            <mat-option *ngFor="let country of countryList" [value]="country.code">
                                                {{country.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div fxLayoutAlign="end">
                                <button class="seconizeButton" mat-stroked-button matStepperPrevious>Back</button>

                                <button [matTooltip]="enabledButton?'':'Please select all fields'"
                                    [disabled]="organizationFormGroup.invalid" class="seconizeButton ml-1"
                                    mat-flat-button color="primary"
                                    (click)="this.dialog.open(emailDialog);getUnProfessionalEmailDomains()">SCORE</button>
                            </div>

                        </form>

                    </mat-step>
                </mat-stepper>


                <!-- SHORT REPORT / RISK STATEMENT -->
                <div *ngIf="showRiskTab">
                    <h3 class="cardTitle">Risk Statement
                        <button class="close-button" mat-icon-button (click)="reset_CVE_ID()">
                            <mat-icon class="close-icon">close</mat-icon>
                        </button>
                    </h3>
                    <div *ngIf="riskScore" [fxLayout]="handsetQuery.matches || smallMobileQuery?'row wrap':'row'">
                        <div [fxFlex]="handsetQuery.matches || smallMobileQuery.matches?'fit-content':70"
                            style="text-align: justify">
                            Your
                            <b> {{ this.organizationFormGroup.controls.industry.value }}</b>
                            Organization operating in
                            <b>
                                <span
                                    *ngFor="let item of this.organizationFormGroup.controls.country.value;let i=index">{{reportService.getCountryNameByCode(item)}}<span
                                        *ngIf="i<this.organizationFormGroup.controls.country.value.length-1">, </span>
                                </span>
                            </b>
                            is at
                            <b>{{ reportService.getSeverity(riskScore) | titlecase}} </b>
                            Risk of getting impacted by
                            <b>{{ staticCveID }}</b>.
                            This is due to an <b>{{ assetReachability.value | titlecase }}</b>
                            facing vulnerable IT Asset.
                            <span *ngIf="dataClass.value || serviceClass.value">
                                Potential Impact could be
                                <span *ngIf="dataClass.value">loss
                                    of <b>{{ dataClass.value }}</b></span>
                                <span *ngIf="dataClass.value && serviceClass.value"> or</span>
                                <span *ngIf="serviceClass.value">
                                    Disruption of <b>{{ serviceClass.value }}</b>
                                </span>.
                            </span>
                            You are
                            recommended to reach out to
                            <b>{{ vendor.value | titlecase}}</b> for patch and remediation information.
                        </div>
                        <seconize-gauge [fxFlex]="handsetQuery.matches || smallMobileQuery.matches?100:30"
                            fxLayoutAlign="center" *ngIf="!isLoading && riskScore" [score]="riskScore"
                            [title]="'Risk Score'">
                        </seconize-gauge>
                    </div>

                    <div *ngIf="!riskScore" style="text-align: justify">
                        Your <b>{{this.organizationFormGroup.controls.industry.value}}</b> Organization operating in
                        <b>
                            <span
                                *ngFor="let item of this.organizationFormGroup.controls.country.value;let i=index">{{reportService.getCountryNameByCode(item)}}<span
                                    *ngIf="i<this.organizationFormGroup.controls.country.value.length-1">, </span>
                            </span>
                        </b>
                        is not at risk of getting impacted by <b>{{ staticCveID }}</b>. This is due to no
                        applicable IT
                        assets were identified.
                    </div>


                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" class="mt-2 mb-1">

                        <!-- <a href="{{ url }}" mat-flat-button color="primary" class="seconizeButton get-your-score">
                            View Full report</a> -->

                        <button mat-flat-button color="primary" class="seconizeButton get-your-score"
                            (click)="viewFullReport()">
                            View Full Report</button>


                        <div class="cursor-pointer" fxLayoutAlign="center center">

                            <!-- E-MAIL -->
                            <!-- <mat-icon matTooltip="E-mail" class="mail"> mail_outline</mat-icon> -->

                            <!-- TWITTER  -->
                            <a target="_blank"
                                href="http://twitter.com/share?text=My%20Risk%20Report%20for%20{{staticCveID}}%20is&url={{url}}&hashtags=cve,KnowYourCVERisk,KnowYourSCORE">
                                <i matTooltip="Twitter" class="fa fa-twitter" aria-hidden="true"></i>
                            </a>

                            <!-- LINKEDIN  -->
                            <a href="https://www.linkedin.com/sharing/share-offsite/?url={{url}}" target="_blank">
                                <i matTooltip="LinkedIn" class="ml-custom fa fa-linkedin-square" aria-hidden="true"></i>
                            </a>

                            <!-- REDDIT  -->
                            <a href="http://www.reddit.com/r/netsec/submit?url={{url}}&title=My%20Risk%20Report%20for%20{{staticCveID}}"
                                target="_blank">
                                <i matTooltip="Reddit" class="ml-custom fa fa-reddit" aria-hidden="true"></i>
                            </a>

                            <mat-icon matTooltip="Copy" *ngIf="url" color="primary" class="ml-custom"
                                [cdkCopyToClipboard]="url" (cdkCopyToClipboardCopied)="copyToast()">content_copy
                            </mat-icon>

                        </div>
                    </div>


                </div>
            </mat-card>


            <!-- ZOHO FORM -->
            <!-- <iframe frameborder="0" style="height:355px;width:50vw;border:none;" #iframe (load)="onLoad(iframe)"
            src='https://forms.zohopublic.com/seconize/form/RiskScoreContactForm/formperma/vjLkhMXWrIJqvEF746IOF6eBgONeMKg_C2m1pzQ0Obc'></iframe>
            <button (click)="test(iframe)">BUTTON</button> -->

        </div>
    </div>

    <!-- FOOTER  -->
    <app-footer></app-footer>
</section>

<!-- E-MAIL code -->
<ng-template #emailDialog class="dialog">
    <h2 mat-dialog-title>
        Enter Your Email to View SCORE
    </h2>

    <mat-dialog-content>
        <form [formGroup]="emailFormGroup">

            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" placeholder="yourname@company.com"
                    (keyup)='checkEmailPattern($event)'>

                <mat-error class="mt-1" *ngIf="emailFormGroup.controls.email.hasError('pattern')">
                    Enter a valid Email Id
                </mat-error>
                <mat-error class="mt-1" *ngIf="emailFormGroup.controls.email.hasError('required')">
                    Email Id is required
                </mat-error>

                <mat-error class="mt-1" *ngIf="emailFormGroup.controls.email.hasError('notMatched')">
                    Enter Professional Email Id
                </mat-error>
            </mat-form-field>

            <div>
                <button mat-flat-button mat-dialog-close class="seconizeButton blue mt-1"
                    [disabled]='emailFormGroup.invalid' (click)="getScore()">
                    Send
                </button>
            </div>
            <div>
                <mat-checkbox formControlName="receiveEmail" class="full-width mt-1">
                    I Agree to receive occasional emails from Risk-Score.
                </mat-checkbox>
            </div>
            <div>
                <mat-checkbox formControlName="privacy_policy" class="full-width">I have reviewed <a
                        href="https://riskscore.info/termsandconditions" target="_blank" color="primary">Terms of
                        Use</a> and <a href="https://seconize.co/privacy-policy/" target="_blank"
                        color="primary">Privacy Policy.</a>
                </mat-checkbox>
            </div>
        </form>
    </mat-dialog-content>
</ng-template>