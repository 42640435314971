import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, Injector, OnInit, PLATFORM_ID, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ReportService } from './report.service';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  providers: []
})
export class ReportComponent implements OnInit {


  title = 'Seconize Contextual Risk Enumeration System (SCORES)';
  mobileQuery: MediaQueryList;
  smallMobileQuery: MediaQueryList
  smallerMobileQuery: MediaQueryList
  handsetQuery: MediaQueryList
  smallestMobileQuery: MediaQueryList
  constructor(
    private breakpointObserver: BreakpointObserver,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public reportService: ReportService,
    private fb: FormBuilder,
    public snackbar: MatSnackBar,
    private titleService: Title,
    private metaTagService: Meta,
    private router: Router,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: object,
    private injector: Injector,
    private http: HttpClient
  ) {
    //handset
    this.handsetQuery = media.matchMedia('(max-width: 1100px)');
    this._handsetQueryListener = () => changeDetectorRef.detectChanges();
    this.handsetQuery.addListener(this._handsetQueryListener);
    //mobile
    this.mobileQuery = media.matchMedia('(max-width: 900px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    //small screen mobile
    this.smallMobileQuery = media.matchMedia('(max-width: 799px)');

    this._smallMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallMobileQuery.addListener(this._smallMobileQueryListener);
    //small screen mobile
    this.smallerMobileQuery = media.matchMedia('(max-width: 590px)');
    this._smallerMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallerMobileQuery.addListener(this._smallerMobileQueryListener);
    //smallest screen mobile
    this.smallestMobileQuery = media.matchMedia('(max-width: 480px)');
    this._smallestMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallestMobileQuery.addListener(this._smallestMobileQueryListener);
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'A free cyber risk scoring tool for vulnerabilities' }
    );
    this.metaTagService.addTags([
      // twitter 
      { name: 'twitter:creator', content: '@seconize' },
      { name: 'twitter:title', content: 'Seconize Contextual Risk Enumeration System (SCORES)' },
      { name: 'twitter:description', content: 'A free cyber risk scoring tool for vulnerabilities' },
      { name: 'twitter:url', content: 'https://seconize.co' },
      // { name: 'twitter:card', content: 'Seconize' },
      { name: 'twitter:image:alt', content: 'Seconize' },

      // open graph 
      { property: 'og:title', content: 'Seconize Contextual Risk Enumeration System (SCORES)' },
      { property: 'og:description', content: 'A free cyber risk scoring tool for vulnerabilities' },
      { property: 'og:type', content: 'article' },
      { property: 'og:image', content: 'https://seconize.co/wp-content/uploads/2021/11/SECONIZE_LOGO_Transparent_-700x125-1.png' },
      { property: 'og:url', content: 'https://seconize.co' },

    ]);


    if (isPlatformServer(this.platformId)) {
      console.log(this.injector.get('host'))
    } else {
      this.reportID.setValue(document.location.pathname.split('/').reverse()[0])
    }

    this.getScoreByID()


  }
  private _mobileQueryListener: () => void;
  private _smallMobileQueryListener: () => void;
  private _smallerMobileQueryListener: () => void;
  private _handsetQueryListener: () => void;
  private _smallestMobileQueryListener: () => void;
  reportID = new FormControl('', [Validators.required])

  reportData: any = {}
  confidentialityValue: any = 0;
  integrityValue: any = 0;
  availabilityValue: any = 0;
  assetRankValue: any = 0;

  getScoreByID() {
    if (this.reportID.invalid) {
      return
    }
    this.reportService.getScoreByID(this.reportID.value).subscribe((data: any) => {
      this.isLoading = false
      if (data.success) {
        this.reportData = data.data

        this.riskScore = this.reportData.riskScore
        this.reportData.geography = this.reportData.geography.split(",")
        this.cveID.setValue(this.reportData.cveId)

        this.reportData.createTimeStamp = new Date(this.reportData.createTimeStamp + ' UTC')
        this.reportData.createTimeStamp.toString()
        this.confidentialityValue = this.getValueFromRating(this.reportData.confidentialityRating)
        this.integrityValue = this.getValueFromRating(this.reportData.integrityRating)
        this.availabilityValue = this.getValueFromRating(this.reportData.availabilityRating)
        this.assetRankValue = this.reportData.assetRank
        if (isPlatformServer(this.platformId)) {
          console.log(this.injector.get('host'))
        } else {
          this.url = document.location.href
        }
        this.getScore()
      }
      else {
        this.router.navigateByUrl('/')
      }

    })

  }

  getValueFromRating(rating: any) {
    if (rating == 0) {
      return 0;
    } else if (rating == 1) {
      return 40;
    } else if (rating == 2) {
      return 60;
    } else if (rating == 3) {
      return 80;
    } else if (rating == 4) {
      return 90;
    } else if (rating == 5) {
      return 100;
    }
    return 0
  }


  cveData: any = ''
  cveRegex: string = '^CVE-[0-9]{4}-[0-9]{4,7}$'
  cveID = new FormControl('', [Validators.required, Validators.pattern(this.cveRegex)])
  url: string = ''

  ngOnInit(): void { }


  copyToast() {
    this.snackbar.open("Shareable Link Copied!", '', {
      duration: 1500,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: ['copy-snackbar']
    });
  }
  isLoading: boolean = true
  riskScore: number = 0

  exploitCodeExists: boolean = true
  industryMatching: boolean = false
  malwareActive: boolean = false
  malwareReported: boolean = false
  countryMatching: boolean = false


  getScore() {
    if (this.cveID.invalid) {
      this.snackbar.open("Invalid CVE ID", '', {
        duration: 1500,
        horizontalPosition: 'end',
        verticalPosition: 'top',
        panelClass: ['error-snackbar']
      });
      return
    }


    this.isLoading = true


    this.reportService.getCVEfromSRI(this.cveID.value).subscribe(data => {

      this.isLoading = false
      this.cveData = data.data.vtstats.cves[0]

      if (this.cveData.referenceUrls)
        this.cveData.referenceUrls = this.cveData.referenceUrls.split(',')
      if (this.cveData.countriesImpacted && this.cveData.countriesImpacted.includes(','))
        this.cveData.countriesImpacted = this.cveData.countriesImpacted.replaceAll(',', ', ')
      if (this.cveData.industriesImpacted && this.cveData.industriesImpacted.includes(','))
        this.cveData.industriesImpacted = this.cveData.industriesImpacted.replaceAll(',', ', ')
    })

  }

  // showShareableIcons: boolean = false
  setCVEinLocalStorage() {
    if (isPlatformBrowser(this.platformId)) {

      localStorage.setItem('cveID', this.cveID.value)
    }
  }


  downloadReport() {
    // const puppeteer = require("puppeteer");

    // (async () => {
    //   const browser = await puppeteer.launch();
    //   const page = await browser.newPage();
    //   await page.goto("http://localhost:4200/", { waitUntil: "networkidle0" });
    //   await page.pdf({
    //     printBackground: true,
    //     path: "filename.pdf",
    //     format: "letter",
    //   });
    //   await browser.close();
    // })();


    // const link = document.createElement('a');
    // link.setAttribute('target', '_blank');
    // link.setAttribute('href', 'assets/shape.png');
    // link.setAttribute('download', `report.pdf`);
    // document.body.appendChild(link);
    // link.click();
    // link.remove();
  }


  step = 5;
  max = 100;
  min = 0;
  tickInterval = 5;
  confidentialityMax = 100;
  confidentialityMin = 0;
  integrityMax = 100;
  integrityMin = 0;
  availabilityMax = 100;
  availabilityMin = 0;

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.smallMobileQuery.removeListener(this._smallMobileQueryListener);
    this.smallerMobileQuery.removeListener(this._smallerMobileQueryListener);
    this.smallestMobileQuery.removeListener(this._smallerMobileQueryListener);
    this.handsetQuery.removeListener(this._handsetQueryListener);

  }

  emailFormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,7}$")]],
    receiveEmail: [true, [Validators.requiredTrue]],
    privacy_policy: [true, [Validators.requiredTrue]]
  });

  showLoader: boolean = false
  sendEmail() {
    if (this.reportID.invalid) {
      return
    }

    let body = {
      "searchMap": {
        "ID": this.reportID.value,
        "EMAIL_ID": this.emailFormGroup.controls.email.value
      }
    }

    this.showLoader = true
    this.reportService.sendEmail(body).subscribe((data: any) => {
      this.showLoader = false

      if (data.success) {
        this.snackbar.open('Success', '', {
          duration: 1500,
          horizontalPosition: 'end',
          verticalPosition: 'top',
          panelClass: ['success-snackbar']
        });
      } else {
        this.snackbar.open('Something went wrong!', '', {
          duration: 1500,
          horizontalPosition: 'end',
          verticalPosition: 'top',
          panelClass: ['error-snackbar']
        });
      }

    })

  }

  unProfessionalEmailDomainsList: any = []

  getUnProfessionalEmailDomains() {
    this.http.get('https://gist.githubusercontent.com/ammarshah/f5c2624d767f91a7cbdc4e54db8dd0bf/raw/660fd949eba09c0b86574d9d3aa0f2137161fc7c/all_email_provider_domains.txt', { responseType: 'text' }).subscribe((data: any) => {
      this.unProfessionalEmailDomainsList = data.split('\n')
    });
  }

  checkEmailPattern(event: any) {
    if (!!~event.target.value?.indexOf("@")) {
      if (this.unProfessionalEmailDomainsList.includes(event.target.value?.split('@')[1])) {
        this.emailFormGroup.controls.email.setErrors({
          notMatched: true
        })
      }
    }
  }

}
