import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  mobileQuery: MediaQueryList;
  smallMobileQuery: MediaQueryList
  smallerMobileQuery: MediaQueryList
  handsetQuery: MediaQueryList
  smallestMobileQuery: MediaQueryList

  constructor(media: MediaMatcher, changeDetectorRef: ChangeDetectorRef, private http: HttpClient) {
    //handset

    this.handsetQuery = media.matchMedia('(max-width: 1100px)');
    this._handsetQueryListener = () => changeDetectorRef.detectChanges();
    this.handsetQuery.addListener(this._handsetQueryListener);
    //mobile
    this.mobileQuery = media.matchMedia('(max-width: 900px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    //small screen mobile
    this.smallMobileQuery = media.matchMedia('(max-width: 799px)');

    this._smallMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallMobileQuery.addListener(this._smallMobileQueryListener);
    //small screen mobile
    this.smallerMobileQuery = media.matchMedia('(max-width: 590px)');
    this._smallerMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallerMobileQuery.addListener(this._smallerMobileQueryListener);
    //smallest screen mobile
    this.smallestMobileQuery = media.matchMedia('(max-width: 480px)');
    this._smallestMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallestMobileQuery.addListener(this._smallestMobileQueryListener);
  }

  @Input() pageName: any;

  private _mobileQueryListener: () => void;
  private _smallMobileQueryListener: () => void;
  private _smallerMobileQueryListener: () => void;
  private _handsetQueryListener: () => void;
  private _smallestMobileQueryListener: () => void;

  ngOnInit(): void {


    // const url = 'https://www.cisa.gov/sites/default/files/feeds/known_exploited_vulnerabilities.json'
    // console.log(fetch(url));

    // async function fech() {
    //   const res = await fetch(url)
    //   const data = await res.json();
    //   console.log(data);
      
    // }
    // fech()
    // this.http.get(url).subscribe((res: any) => {
    //   console.log(res);
    //   // console.log(res.vulnerabilities);
    // })
  }
  data: any
  fillerNav: any = [
    { name: 'Home', route: '/', isActive: false },
    { name: 'Reports', route: '/reports', isActive: false },
    { name: 'FAQ', route: '/faq', isActive: false },
    { name: 'How It Works', route: '/howitworks', isActive: false }
  ]

  navItems: any = [
    { name: 'Home', route: '/' },
    { name: 'How It Works', route: '/howitworks' },
    { name: 'Reports', route: '/reports' },
    { name: 'FAQ', route: '/faq' }

  ]
}
