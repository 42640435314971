import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecordsService {

  constructor(
    private http: HttpClient,
  ) { }

  getAllReports(body: any): Observable<any> {
    return this.http.post<any>(environment.url + '/search', body)
      .pipe(catchError((e: HttpErrorResponse): any => {
        console.log(e)
      }))
  }

  getStats(body: any): Observable<any> {
    return this.http.post<any>(environment.url + '/stats', body)
      .pipe(catchError((e: HttpErrorResponse): any => {
        console.log(e)
      }))
  }

}
