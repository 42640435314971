import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FaqComponent } from './faq/faq.component';
import { HomeComponent } from './home/home.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { ReportComponent } from './report/report.component';
import { RecordsComponent } from './records/records.component';
import { TermsandconditionsComponent } from './termsandconditions/termsandconditions.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'cve/:id/new', component: HomeComponent },
  { path: 'cve/:cveid/:uid', component: ReportComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'faq/:id', component: FaqComponent },
  { path: 'howitworks', component: HowItWorksComponent },
  { path: 'reports', component: RecordsComponent },
  { path: 'termsandconditions', component: TermsandconditionsComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
