<section class="gauge-wrapper">
    <div class="gauge four rischio">
        <div class="slice-colors">
            <div [style.background-color]="(this.riskScore > 0 && this.riskScore < 40)?'#c3c3c3':''"
                class="st slice-item">
            </div>
            <div [style.background-color]="(this.riskScore >= 40 && this.riskScore < 60)?'#72c1e1':''"
                class="st slice-item"></div>
            <div [style.background-color]="(this.riskScore >= 60 && this.riskScore < 80)?'#e7ba15':''"
                class="st slice-item"></div>
            <div [style.background-color]="(this.riskScore >= 80 && this.riskScore < 90)?'#f36926':''"
                class="st slice-item"></div>
            <div [style.background-color]="(this.riskScore >= 90 && this.riskScore <= 100)?'#bf1f2a':''"
                class="st slice-item"></div>
            <div class="st slice-item"></div>
        </div>
        <div class="needle risk-needle"></div>
        <div class="gauge-center">
            <div class="number">
                {{riskScore}}</div>
        </div>
    </div>
    <h5 *ngIf="title" id="gaugeScore" [matTooltip]="titleTooltip" class="mb-0 center"><strong>{{title}}</strong>
    </h5>
</section>