import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-termsandconditions',
  templateUrl: './termsandconditions.component.html',
  styleUrls: ['./termsandconditions.component.scss']
})
export class TermsandconditionsComponent implements OnInit {
  mobileQuery: MediaQueryList;
  smallMobileQuery: MediaQueryList
  smallerMobileQuery: MediaQueryList
  handsetQuery: MediaQueryList
  smallestMobileQuery: MediaQueryList
  constructor(
    private breakpointObserver: BreakpointObserver,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
  ) {
    //handset
    this.handsetQuery = media.matchMedia('(max-width: 1100px)');
    this._handsetQueryListener = () => changeDetectorRef.detectChanges();
    this.handsetQuery.addListener(this._handsetQueryListener);
    //mobile
    this.mobileQuery = media.matchMedia('(max-width: 900px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    //small screen mobile
    this.smallMobileQuery = media.matchMedia('(max-width: 799px)');

    this._smallMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallMobileQuery.addListener(this._smallMobileQueryListener);
    //small screen mobile
    this.smallerMobileQuery = media.matchMedia('(max-width: 590px)');
    this._smallerMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallerMobileQuery.addListener(this._smallerMobileQueryListener);
    //smallest screen mobile
    this.smallestMobileQuery = media.matchMedia('(max-width: 480px)');
    this._smallestMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallestMobileQuery.addListener(this._smallestMobileQueryListener);
  }
  private _mobileQueryListener: () => void;
  private _smallMobileQueryListener: () => void;
  private _smallerMobileQueryListener: () => void;
  private _handsetQueryListener: () => void;
  private _smallestMobileQueryListener: () => void;
  ngOnInit(): void {
  }
  
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.smallMobileQuery.removeListener(this._smallMobileQueryListener);
    this.smallerMobileQuery.removeListener(this._smallerMobileQueryListener);
    this.smallestMobileQuery.removeListener(this._smallerMobileQueryListener);
    this.handsetQuery.removeListener(this._handsetQueryListener);

  }
}
