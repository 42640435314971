import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'seconize-gauge',
  templateUrl: './seconize-gauge.component.html',
  styleUrls: ['./seconize-gauge.component.scss'],

})
export class SeconizeGaugeComponent implements OnInit {

  constructor() { }
  @HostBinding("style.--needleProperty")
  @Input() score: any = 0   // risk score
  @Input() title: any = ''  // title to be displayed below gauge
  @Input() titleTooltip: any = '' // tooltip on Title Hover
  @Input() needleProperty: any = ''// for unique instance
  riskScore: any = 0

  ngOnInit(): void {

    this.riskScore = this.score

    // using css variable to update Gauge needle 
    document.querySelector("body")!.style.setProperty(`--${this.needleProperty}`, this.riskScore);
  }

}