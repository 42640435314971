<section fxLayout="column" class="page-container">

    <!-- HEADER -->
    <app-header [pageName]="'TnC'"></app-header>

    <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="content-container">
        <div fxLayout="column" fxLayoutAlign="stretch center" class="content-wrapper"
            [ngClass]="{'w-100':mobileQuery.matches,'w-75':!mobileQuery.matches}">

            <!-- CONTENT  -->
            <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="content">
                <mat-card fxFlex fxLayout="column" class="mat-elevation-z0  w-80" fxLayoutGap="10px">
                    <div fxFlex fxLayout="column">
                        <h1>
                            Seconize SCORES Terms of Use v1.0!
                        </h1>
                        <div>By accessing, browsing, or using this website, and/or downloading any of its software,
                            content, or information, you acknowledge that you have read, understand, and agree to be
                            bound by these terms of use. If you do not agree to all these terms, do not use this website
                            and/or its software, content, or information.</div>
                        <div>
                            SCORES (Seconize Contextual Risk Enumeration System) is a free risk scoring tool for
                            vulnerabilities. The SCORES Website hosted at <a href="https://riskscore.info" class="wb-ba"
                                target="_blank">https://riskscore.info</a>
                            (“Site”) is a service
                            made available by Seconize Technologies Private Limited, including its subsidiaries
                            (“Seconize”).
                            All software, documentation, survey information, including the summarization
                            of configuration information of webservers provided to the Site, and other materials
                            provided on and through this Site (“Content”) may be used only under the following terms and
                            conditions (“Terms of Use”) and any applicable licenses. SCORES is designed and developed
                            for educational and awareness purposes only. Risk-Score does not want to receive
                            confidential
                            information from you through this Site. ANY INFORMATION OR MATERIAL SENT TO THIS SITE WILL
                            BE DEEMED NOT TO BE CONFIDENTIAL. You acknowledge that you are responsible for the Content
                            that you submit, not Risk-Score, have full responsibility for that Content, including its
                            legality, reliability, appropriateness, originality, patent, and copyright.
                        </div>
                    </div>
                    <div fxFlex fxLayout="column">
                        <h1>No Unlawful or Prohibited Use</h1>

                        <div>As a condition of your use of the Site or its Content, you will not use the Site or its
                            Content
                            for any purpose that is unlawful, unethical, inappropriate, or prohibited by these terms,
                            conditions, and notices or any applicable law. You may not use the Site or its Content in
                            any
                            manner that could damage, disable, overburden, or impair any Risk-Score server, or the
                            network(s)
                            connected to any Risk-Score server, or interfere with any other party's use and enjoyment of
                            any
                            services. You may not attempt to gain unauthorized access to the Site or its Content, other
                            accounts, computer systems or networks connected to any Risk-Score server or the Site,
                            through
                            hacking, password mining or any other means. </div>
                    </div>
                    <div fxFlex fxLayout="column">
                        <h1>Notice Specific to Content Available on This Site
                        </h1>
                        <div> Permission to use Content (such as surveys, white papers, press releases, datasheets, and
                            FAQs) from the Site is granted and shall be licensed under a Creative Commons Attribution
                            3.0 License
                            (<a class="wb-ba" href="https://creativecommons.org/licenses/by/3.0/us/"
                                target="_blank">https://creativecommons.org/licenses/by/3.0/us/</a>).
                        </div>

                        <div>Content specified above does not include the design or layout of the Site, Risk-Score.co
                            Web
                            site or any other Risk-Score owned, operated, licensed, or controlled site. Elements of
                            Risk-Score Web sites are protected by trade dress, trademark, unfair competition, and other
                            laws and may not be copied or imitated in whole or in part. No logo, graphic, sound or image
                            from any Risk-Score Web site may be copied or retransmitted unless expressly permitted by
                            Risk-Score. </div>


                    </div>
                    <div fxFlex fxLayout="column">
                        <h1> Username, Password, Security </h1>

                        <div> If the Site or any part thereof requires you to create or submit a username and password,
                            you must complete the registration process by providing Risk-Score with current, complete,
                            and
                            accurate information as prompted by the applicable registration enrolment form. You may be
                            prompted to choose a username and password, or Risk-Score may randomly generate a password
                            for
                            you. You acknowledge and agree to be entirely responsible for maintaining the
                            confidentiality of your password. Furthermore, you are entirely responsible for any all
                            activities that occur under/or with your username and password. You agree to notify
                            Risk-Score
                            immediately of any unauthorized use of your username or password of any breach of security.
                            Risk-Score will not be liable for any loss that you may incur because of someone else using
                            your username or password, either with or without your knowledge.


                        </div>
                    </div>
                    <div fxFlex fxLayout="column">
                        <h1>Third Party Services or Resources </h1>

                        <div>The Site may contain content or services provided by third parties and/or links to third
                            party services, website, or other resources. Risk-Score is not responsible or liable for the
                            availability, accuracy, functionality, adherence to third party policies, or legality of,
                            and Risk-Score does not endorse such websites or resources, or the content, products, or
                            services available from such websites. You are solely responsible for and assume all risk
                            arising from your use of any such websites, services, or resources. </div>
                    </div>
                    <div fxFlex fxLayout="column">
                        <h1>Copyright</h1>

                        <div>The API(s), the Site and the Content contain material that is protected by India copyright
                            law and trade secret law, and by international treating provisions. All rights not granted
                            to You by this Agreement are expressly reserved by Risk-Score. You shall not remove any
                            proprietary notice of Risk-Score from the API(s), the Site, or the Content nor any copy of
                            the
                            same. </div>

                    </div>
                    <div fxFlex fxLayout="column">
                        <h1>Privacy</h1>

                        <!-- <div>Seconize, in providing the Site and/or the Content, may collect certain information in
                            accordance with Seconize’ Privacy Policy at: <a href="https://seconize.co/privacy-policy"
                                target="_blank" class="wb-ba">https://seconize.co/privacy-policy</a>.
                        </div> -->
                        <div>
                            We may collect hostnames, IP addresses, and other information submitted to us. We will not
                            share that information with a third-party or otherwise publish it. We may use the collected
                            information for the following purposes:
                            <ul>
                                <li>Provide, operate, and maintain our website</li>
                                <li>Improve, personalize, and expand our website </li>
                                <li>Understand and analyse how you use our website </li>
                                <li>Develop new products, services, features, and functionality</li>
                                <li>Communicate with you, either directly or through one of our partners, including for
                                    customer service, to provide you with updates and other information relating to the
                                    website, and for marketing and promotional purposes</li>
                                <li>Send you emails if opted for </li>
                                <li>Find and prevent fraud </li>
                                <li>Store reports on third-party systems for the purpose of caching and web site load
                                    distribution only (e.g., on a CDN).</li>
                            </ul>
                            <div>RISK-SCORE OR LICENSORS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE
                                INFORMATION
                                CONTAINED ON THE SITE, THE API(S) AND/OR IN THE CONTENT. THE CONTENT AND THE API(S) ARE
                                PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. RISK-SCORE OR LICENSORS HEREBY DISCLAIM
                                ALL
                                WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, INCLUDING ALL WARRANTIES AND
                                CONDITIONS OF MERCHANTABILITY, WHETHER EXPRESS, IMPLIED OR STATUTORY, FITNESS FOR A
                                PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. IN NO EVENT SHALL RISK-SCORE OT ITS
                                LICENSORS BE LIABLE FOR ANY SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES
                                WHATSOEVER RESULTING FROM THE SITE, API(S), THE CONTENT, LOSS OF USE, DATA OR PROFITS,
                                WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR
                                IN CONNECTION WITH THE USE OR PERFORMANCE OF THE API(S) OR THE INFORMATION AVAILABLE
                                FROM THE SITE.</div>

                        </div>
                    </div>
                    <div fxFlex fxLayout="column">
                        <h1>Release and Indemnity </h1>

                        <div>YOU HEREBY RELEASE AND WAIVE ANY AND ALL CLAIMS AND/OR LIABILITY AGAINST RISK-SCORE ARISING
                            FROM OR IN CONNECTION WITH YOUR USE OF THE SITE, THE API(S) OR ANY CONTENT. YOU ALSO AGREE
                            TO DEFEND, INDEMNIFY AND HOLD HARMLESS, RISK-SCORE, FROM AND AGAINST, ANY AND ALL CLAIMS OR
                            LIABILITY, INCLUDING COSTS AND ATTORNEYS' FEES, ARISING FROM OR IN CONNECTION WITH YOUR USE
                            OF THE SITE, THE API(S) OR THE CONTENT OR FOR YOUR FAILURE TO ABIDE BY APPLICABLE LAWS.
                        </div>
                    </div>
                    <div fxFlex>
                        Automated Access

                        Except where otherwise noted, our Site is designed for access using a browser or similar
                        manually operated HTTP client. Using automation to request site assessments and/or extract
                        assessment results from HTML pages (“scraping”) is expressly forbidden. For other purposes,
                        automated access is permitted provided the agent being used accurately identifies itself and
                        complies with the Robot Exclusion Standard (<a
                            href="http://en.wikipedia.org/wiki/Robots_Exclusion_Standard" target="_blank"
                            class="wb-ba">http://en.wikipedia.org/wiki/Robots_Exclusion_Standard</a>). If you want to
                        automate SCORES, use our product Risk-Score DeRisk Centre, which are specifically designed for
                        this purpose. We reserve the right to block or throttle your access to our sites (either
                        automated or manual), at our sole discretion and at any time and without notice, for any reason.
                    </div>
                    <div fxFlex fxLayout="column">
                        <h1>Governing Law </h1>

                        <div>By visiting or using the Site, you agree that the laws of India, without regard to
                            principles of conflict of laws, will govern this Agreement and any dispute of any sort that
                            might arise between you and us. With respect to any disputes or claims, you agree not to
                            commence or prosecute any action in connection therewith other than in the state and federal
                            courts of India, and you hereby consent to, and waive all defences of lack of personal
                            jurisdiction and forum non-convenience with respect to, venue and jurisdiction in the state
                            and federal courts of India. </div>
                    </div>
                    <div fxFlex fxLayout="column">
                        <h1>General</h1>

                        <div>These Terms of Use constitute the entire agreement between you and us regarding the use of
                            the Site, superseding any prior agreements between you and us relating to your use of the
                            Site. The failure by us to exercise or enforce any right or provision of these Terms of Use
                            shall not constitute a waiver of such right or provision in that or any other instance. If
                            any provisions of these Terms of Use are held invalid, the remainder of this Agreement shall
                            continue in full force and effect. If any provision of these Terms of Use shall be deemed
                            unlawful, void, or for any reason unenforceable, that that provision shall be deemed
                            severable from these Terms of Use and shall not affect the validity and enforceability of
                            any remaining provisions. </div>
                    </div>

                </mat-card>
            </div>




        </div>
    </div>

    <!-- FOOTER -->
    <app-footer></app-footer>
</section>