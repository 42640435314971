import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { isPlatformServer } from '@angular/common';
import { Component, Inject, Injector, OnInit, PLATFORM_ID, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { RecordsService } from './records.service';

@Component({
  selector: 'app-records',
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.scss']
})
export class RecordsComponent implements OnInit {

  displayedColumns: string[] = [
    'cve',
    'cvss',
    'exploit',
    'malware',
    'assetReachability',
    'threatScore',
    'dataRating',
    'serviceRating',
    'impactScore',
    'riskScore'
  ];
  mobileQuery: MediaQueryList;
  smallMobileQuery: MediaQueryList
  smallerMobileQuery: MediaQueryList
  handsetQuery: MediaQueryList
  smallestMobileQuery: MediaQueryList
  constructor(
    private breakpointObserver: BreakpointObserver,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private recordsService: RecordsService,
    @Inject(PLATFORM_ID) private platformId: object,
    private injector: Injector,
  ) {
    //handset
    this.handsetQuery = media.matchMedia('(max-width: 1100px)');
    this._handsetQueryListener = () => changeDetectorRef.detectChanges();
    this.handsetQuery.addListener(this._handsetQueryListener);
    //mobile
    this.mobileQuery = media.matchMedia('(max-width: 900px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    //small screen mobile
    this.smallMobileQuery = media.matchMedia('(max-width: 799px)');

    this._smallMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallMobileQuery.addListener(this._smallMobileQueryListener);
    //small screen mobile
    this.smallerMobileQuery = media.matchMedia('(max-width: 590px)');
    this._smallerMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallerMobileQuery.addListener(this._smallerMobileQueryListener);
    //smallest screen mobile
    this.smallestMobileQuery = media.matchMedia('(max-width: 480px)');
    this._smallestMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallestMobileQuery.addListener(this._smallestMobileQueryListener);
    this.isLoading = true
  }
  private _mobileQueryListener: () => void;
  private _smallMobileQueryListener: () => void;
  private _smallerMobileQueryListener: () => void;
  private _handsetQueryListener: () => void;
  private _smallestMobileQueryListener: () => void;
  ngOnInit(): void {

    this.getAllReports()
  }

  dataCollection: any = [];
  dataSource = new MatTableDataSource<any>(this.dataCollection);
  isLoading: boolean
  getAllReports() {

    this.isLoading = true

    if (this.cve_id) {
      this.searchMap['CVE_ID'] = this.cve_id;
    }

    var payload = {
      startIndex: this._page,
      count: this.pageSize.value,
      groupBy: '',
      sortOrder: this.sortOrder,
      sortBy: this.sortBy,
      excludeMap: {},
      searchMap: this.searchMap,
    }
    this.recordsService.getAllReports(payload).subscribe((data: any) => {

      this.isLoading = false

      if (data.success) {
        this.dataCollection = data.data
        this.dataSource = new MatTableDataSource<any>(this.dataCollection);
      }
      else {

        this.dataSource = new MatTableDataSource<any>();
      }
    })
    this.getAllStats(payload)
  }

  getReportURL(cveID: string, reportID: string) {
    if (isPlatformServer(this.platformId)) {
      console.log(this.injector.get('host'))
      return
    } else {
      // console.log(document.location.origin + '/cve/' + cveID + "/" + reportID);
      return document.location.origin + '/cve/' + cveID + "/" + reportID
    }
  }


  getAllStats(payload: any) {

    this.itemCount = this.pageSize.value * this._page;
    this.recordsService.getStats(payload).subscribe((data: any) => {
      if (data.success) {
        if (this._page === 1) {
          this.totalCount = data.data
          this.totalPages = Math.ceil(+this.totalCount / +this.pageSize.value);
        }
      }
    })
  }

  searchMap: any = {};
  // SEARCH BY CVE ID

  public cve_id: any = '';
  searchByCVE_ID() {
    if (this.cve_id == '') {
      delete this.searchMap['CVE_ID'];
      this.cve_id = null
      this.getAllReports()
    } else {
      this._page = 1;
      this.cve_id = this.cve_id.trim();
      this.getAllReports()
    }
  }

  _page: number = 1;
  pageSize = new FormControl('10')
  itemCount: number = this.pageSize.value;
  totalCount: any
  totalPages: any

  changePage(val: number) {
    if (+val >= 0) {
      if (val !== this._page) {
        this._page = val;
        this.getAllReports();
      }
    }
  }
  next() {
    this.changePage(this._page + 1);
  }
  prev() {
    this.changePage(this._page - 1);
  }
  firstPage() {
    this._page = 1;
    this.getAllReports();
  }
  lastPage() {
    this._page = this.totalPages;
    this.getAllReports();
  }


  //server side Sorting
  sort: boolean = false;
  sortBy: string = '';
  sortOrder: string = '';

  sortByColumn(val: string) {
    this.sortBy = val;
    this.sort = !this.sort;
    if (this.sort) this.sortOrder = 'asc';
    else this.sortOrder = 'desc';
    this.getAllReports()
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.smallMobileQuery.removeListener(this._smallMobileQueryListener);
    this.smallerMobileQuery.removeListener(this._smallerMobileQueryListener);
    this.smallestMobileQuery.removeListener(this._smallerMobileQueryListener);
    this.handsetQuery.removeListener(this._handsetQueryListener);

  }
}
