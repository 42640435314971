<div class="spinner-wrapper" *ngIf="showLoader">
    <mat-spinner class="spinner"></mat-spinner>
</div>

<section fxLayout="column" style="min-height: 100vh" class="page-container">

    <!-- HEADER -->
    <app-header [pageName]="'Reports'"></app-header>

    <!-- CONTENT  -->
    <div *ngIf="cveID.valid" fxFlex fxLayout="column" fxLayoutAlign="center center" class="content-container">

        <div fxLayout="column" fxLayoutAlign="center center" class="content-wrapper"
            [ngClass]="{'w-100':mobileQuery.matches,'w-75':!mobileQuery.matches}">

            <!-- Title  -->
            <mat-card class="mat-elevation-z0 mt-1 w-85">
                <div [fxLayout]="smallMobileQuery.matches?'row wrap':'row'"
                    [fxLayoutGap]="smallMobileQuery.matches?'5px':''" fxLayoutAlign="space-between center">

                    <div [fxFlex]="smallMobileQuery.matches?100:''" fxLayout="column">

                        <div class="opacity-8" style="font-weight: 600;font-size: 1rem">
                            Risk Report for {{ cveID.value }}
                        </div>
                        <small>Created by a user from {{ reportData.industry }} Sector on
                            <span matTooltip="YYYY/MM/DD">
                                {{ reportData.createTimeStamp | date:'yyyy/MM/dd h:mm a'}}
                            </span>
                        </small>
                    </div>

                    <div [fxFlex]="smallMobileQuery.matches?100:''" class="cursor-pointer"
                        [ngClass]="{'mt-10px':smallMobileQuery.matches}"
                        [fxLayoutAlign]="!mobileQuery.matches?'end end':''">

                        <mat-icon matTooltip="E-mail" class="mail"
                            (click)="this.dialog.open(emailDialog);getUnProfessionalEmailDomains()">
                            mail_outline</mat-icon>

                        <!-- TWITTER  -->
                        <a target="_blank"
                            href="http://twitter.com/share?text=My%20Risk%20Report%20for%20{{cveID.value}}%20is&url={{url}}&hashtags=cve,KnowYourCVERisk,KnowYourSCORE">
                            <i matTooltip="Twitter" class="fa fa-twitter" aria-hidden="true"></i>
                        </a>

                        <!-- LINKEDIN  -->
                        <a href="https://www.linkedin.com/sharing/share-offsite/?url={{url}}" target="_blank">
                            <i matTooltip="LinkedIn" class="ml-custom fa fa-linkedin-square" aria-hidden="true"></i>
                            <!-- <i class="ml-custom fa fa-linkedin"></i>  -->
                        </a>

                        <!-- REDDIT  -->
                        <a href="http://www.reddit.com/r/netsec/submit?url={{url}}&title=My%20Risk%20Report%20for%20{{cveID.value}}"
                            target="_blank">
                            <i matTooltip="Reddit" class="ml-custom fa fa-reddit" aria-hidden="true"></i>
                        </a>

                        <mat-icon matTooltip="Copy" *ngIf="url" color="primary" class="ml-custom copy"
                            [cdkCopyToClipboard]="url" (cdkCopyToClipboardCopied)="copyToast()">content_copy
                        </mat-icon>

                        <a href="/" (click)="setCVEinLocalStorage()" class="seconizeButton get-your-score ml-1"
                            mat-flat-button color="primary">Know Your SCORE</a>
                    </div>

                </div>

            </mat-card>

            <!-- Main Risk Score -->
            <mat-card class="mat-elevation-z0 mt-1 w-85">
                <div class="mb-1" [fxLayout]="smallMobileQuery.matches?'row wrap':'row'" fxLayoutGap="5px">
                    <div *ngIf="riskScore" fxLayout="column" [fxFlex]="smallMobileQuery.matches?'fit-content':70"
                        fxLayoutGap="7px">

                        <h3 class="cardTitle">Risk Statement</h3>
                        <div *ngIf="reportData" style="text-align: justify">
                            Your
                            <b> {{ reportData.industry }}</b>
                            Organization operating in
                            <b>
                                <span
                                    *ngFor="let item of reportData.geography;let i=index">{{reportService.getCountryNameByCode(item)}}<span
                                        *ngIf="i<reportData.geography.length-1">, </span>
                                </span>
                            </b>
                            is at
                            <b>{{ reportData.severity | titlecase}} </b>
                            Risk of getting impacted by
                            <b>{{ reportData.cveId }}</b>.
                            This is due to an <b>{{ reportData.assetReachability | titlecase}}</b>
                            facing vulnerable IT Asset.
                            <span *ngIf="reportData.dataProfile || reportData.serviceProfile">
                                Potential Impact could be
                                <span *ngIf="reportData.dataProfile">loss
                                    of <b>{{ reportData.dataProfile }} </b> </span>
                                <span *ngIf="reportData.dataProfile && reportData.serviceProfile">or</span>
                                <span *ngIf="reportData.serviceProfile">
                                    Disruption of <b>{{ reportData.serviceProfile }}</b>
                                </span>.
                            </span>


                            You
                            are
                            recommended to reach out to
                            <b>{{ reportData.vendor | titlecase}}</b> for patch and remediation information.
                        </div>
                    </div>
                    <div *ngIf="!riskScore" fxLayout="column" [fxFlex]="smallMobileQuery.matches?'fit-content':70"
                        fxLayoutGap="7px">

                        <h3 class="cardTitle">Risk Statement</h3>
                        <div *ngIf="reportData" style="text-align: justify">
                            Your <b> {{ reportData.value }}</b> Organization operating in
                            <b>
                                <span
                                    *ngFor="let item of reportData.geography;let i=index">{{reportService.getCountryNameByCode(item)}}<span
                                        *ngIf="i<reportData.geography.length-1">, </span>
                                </span>
                            </b>
                            is not at risk of getting impacted by <b>{{ reportData.cveId }}</b>. This is due to no
                            applicable IT
                            assets were identified.
                        </div>
                    </div>

                    <div fxLayoutAlign="center center" [fxFlex]="smallMobileQuery.matches?100:30">
                        <seconize-gauge *ngIf="!isLoading" [score]="riskScore" [title]="'Risk Score'">
                        </seconize-gauge>
                    </div>
                </div>
            </mat-card>

            <!-- Threat and Impact Context  -->
            <mat-card class="mat-elevation-z0 mt-1 w-85" style="text-align: justify">

                <!-- Threat -->
                <div fxLayoutAlign="center start" [fxLayout]="smallMobileQuery.matches?'row wrap':'row'" class="mb-1">
                    <div [fxFlex]="smallMobileQuery.matches?100:70" fxLayout="column" fxLayoutGap="7px">
                        <h3 class="cardTitle">Threat Context</h3>
                        <!-- Description -->
                        <div *ngIf="cveData.description">
                            <small fxFlex="30"><b>Description:</b></small>
                            <div fxFlex>
                                {{ cveData.description }}
                            </div>
                        </div>

                        <!-- CVSS v2 -->
                        <div *ngIf="cveData.cvssV2Score && !smallerMobileQuery.matches">
                            <small fxFlex="30"><b>CVSS v2:</b></small>
                            <div fxFlex>
                                <div>
                                    <b fxFlex="10">{{ cveData.cvssV2Score !=0?cveData.cvssV2Score:'--' }}</b>
                                    <b class="ml-1">Vector:&nbsp;</b>
                                    <span class="wb-ba">{{ cveData.cvssV2Vector!=null?cveData.cvssV2Vector:'--'
                                        }}</span>
                                    <a style="height: 1px" href="https://www.first.org/cvss/v2/guide" target="_blank">
                                        <mat-icon style="transform:scale(0.8)" class="ml-1"
                                            matTooltip="https://www.first.org/cvss/v2/guide">info_outline
                                        </mat-icon>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div *ngIf="cveData.cvssV2Score && smallerMobileQuery.matches">
                            <small fxFlex="30"><b>CVSS v2:</b></small>
                            <div fxFlex>
                                <div>
                                    <b fxFlex="fit-content">{{ cveData.cvssV2Score !=0?cveData.cvssV2Score:'--' }}</b>

                                </div>

                            </div>
                        </div>
                        <div *ngIf="cveData.cvssV2Score && smallerMobileQuery.matches">
                            <small fxFlex="30"><b>Vector:</b></small>
                            <div fxFlex>
                                <div>
                                    <small class="wb-ba" fxFlex="fit-content">{{
                                        cveData.cvssV2Vector!=null?cveData.cvssV2Vector:'--' }}</small>
                                    <a style="height: 1px" href="https://www.first.org/cvss/v2/guide" target="_blank">
                                        <mat-icon style="transform:scale(0.8)" class="ml-1"
                                            matTooltip="https://www.first.org/cvss/v2/guide">info_outline
                                        </mat-icon>
                                    </a>

                                </div>

                            </div>
                        </div>


                        <!-- CVSS v3 -->
                        <div *ngIf="cveData.cvssV3Score  && !smallerMobileQuery.matches">
                            <small fxFlex="30"><b>CVSS v3:</b></small>
                            <div fxFlex>
                                <div>
                                    <b fxFlex="10">{{ cveData.cvssV3Score!=0?cveData.cvssV3Score:'--' }}</b>
                                    <b fxFlex class="ml-1">Vector:&nbsp;</b>
                                    <span class="wb-ba">{{ cveData.cvssV3Vector!=null?cveData.cvssV3Vector:'--'
                                        }}</span>
                                    <a style="height: 1px" href="https://www.first.org/cvss/v3.0/specification-document"
                                        target="_blank">
                                        <mat-icon style="transform:scale(0.8)" class="ml-1"
                                            matTooltip="https://www.first.org/cvss/v3.0/specification-document">
                                            info_outline
                                        </mat-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="cveData.cvssV3Score && smallerMobileQuery.matches">
                            <small fxFlex="30"><b>CVSS v3:</b></small>
                            <div fxFlex>
                                <div>
                                    <b fxFlex="fit-content">{{ cveData.cvssV3Score!=0?cveData.cvssV3Score:'--' }}</b>

                                </div>
                            </div>
                        </div>
                        <div *ngIf="cveData.cvssV3Score && smallerMobileQuery.matches">
                            <small fxFlex="30"><b>Vector:</b></small>
                            <div fxFlex>
                                <div>
                                    <small class="wb-ba" fxFlex="fit-content">{{
                                        cveData.cvssV3Vector!=null?cveData.cvssV3Vector:'--' }}</small>
                                    <a style="height: 1px" href="https://www.first.org/cvss/v3.0/specification-document"
                                        target="_blank">
                                        <mat-icon style="transform:scale(0.8)" class="ml-1"
                                            matTooltip="https://www.first.org/cvss/v3.0/specification-document">
                                            info_outline
                                        </mat-icon>
                                    </a>

                                </div>
                            </div>
                        </div>


                        <!-- Exploit -->
                        <div>
                            <small fxFlex="30"><b>Exploit Available:</b></small>
                            <mat-icon color="warn">
                                {{reportData.exploitCodeExists?'done':'close'}}
                            </mat-icon>
                        </div>

                        <!-- Malware -->
                        <div>
                            <small fxFlex="30"><b>Malware Campaign:</b></small>
                            <mat-icon color="warn">
                                {{reportData.malwareActive?'done':'close'}}
                            </mat-icon>
                        </div>


                        <!-- Industry -->
                        <div *ngIf="cveData.industriesImpacted">
                            <small fxFlex="30"><b>Industry:</b></small>
                            <div fxFlex>
                                {{ cveData.industriesImpacted }}
                            </div>
                        </div>

                        <!-- Countries -->
                        <div *ngIf="cveData?.countriesImpacted">
                            <small fxFlex="30"><b>Countries:</b></small>
                            <div fxFlex>
                                {{ cveData.countriesImpacted }}
                            </div>
                        </div>

                        <!-- Asset Reachability -->
                        <div>
                            <small fxFlex="30"><b>Asset Reachability:</b></small>
                            <div fxFlex>
                                External
                            </div>
                        </div>
                    </div>
                    <div [fxFlex]="smallMobileQuery.matches?100:30">
                        <seconize-gauge fxLayoutAlign="center" style="transform: scale(0.7)" *ngIf="!isLoading"
                            [score]="reportData.threatScore" [title]="'Threat Score'">
                        </seconize-gauge>
                    </div>
                </div>

                <!-- Impact -->
                <div fxLayoutAlign="center start" [fxLayout]="smallerMobileQuery.matches?'row wrap':'row'">
                    <div [fxFlex]="smallerMobileQuery.matches?100:70" fxLayout="column" fxLayoutGap="7px">
                        <h3 class="cardTitle">Impact Context</h3>
                        <!-- Confidentiality -->
                        <div fxLayout="row" fxLayoutAlign="center end">
                            <small fxFlex="28"><b>Confidentiality:</b></small>
                            <mat-slider fxFlex [thumbLabel]="true" [disabled]="true" [tickInterval]="tickInterval"
                                color="primary" [max]="confidentialityMax" [min]="confidentialityMin" [step]="step"
                                value="confidentialityValue" [(ngModel)]="confidentialityValue">
                            </mat-slider>
                        </div>

                        <!-- Integrity -->
                        <div fxLayout="row" fxLayoutAlign="center end">
                            <small fxFlex="28"><b>Integrity:</b></small>
                            <mat-slider fxFlex [disabled]="true" class="w-100" [thumbLabel]="true"
                                [tickInterval]="tickInterval" color="primary" [max]="integrityMax" [min]="integrityMin"
                                [step]="step" value="integrityValue" [(ngModel)]="integrityValue">
                            </mat-slider>
                        </div>

                        <!-- Availability -->
                        <div fxLayout="row" fxLayoutAlign="center end">
                            <small fxFlex="28"><b>Availability:</b></small>
                            <mat-slider fxFlex class="w-100" [disabled]="true" [thumbLabel]="true"
                                [tickInterval]="tickInterval" color="primary" [max]="availabilityMax"
                                [min]="availabilityMin" [step]="step" value="availabilityValue"
                                [(ngModel)]="availabilityValue">
                            </mat-slider>
                        </div>

                        <!-- Rank -->
                        <div fxLayout="row" fxLayoutAlign="center end">
                            <small fxFlex="28"><b>Rank:</b></small>
                            <mat-slider fxFlex class="w-100" [disabled]="true" [thumbLabel]="true"
                                [tickInterval]="tickInterval" color="primary" [max]="availabilityMax"
                                [min]="availabilityMin" [step]="step" value="assetRankValue"
                                [(ngModel)]="assetRankValue">
                            </mat-slider>
                        </div>
                    </div>

                    <div [fxFlex]="smallerMobileQuery.matches?100:30">
                        <seconize-gauge fxLayoutAlign="center" style="transform: scale(0.7)" *ngIf="!isLoading"
                            [score]="reportData.impactScore" [title]="'Impact Score'">
                        </seconize-gauge>
                    </div>
                </div>
            </mat-card>

            <!-- References  -->
            <mat-card class="mat-elevation-z0 mt-1 w-85">
                <div fxLayout="column" fxLayoutGap="7px">
                    <div>
                        <small fxFlex="fit-content"><b>References:</b></small>
                        <div fxFlex>
                            <ul style=" list-style-position: inside;
                        padding-left: 0;">
                                <li *ngFor="let item of cveData.referenceUrls">
                                    <a [href]="item" target="_blank" class="wb-ba">
                                        {{ item }}
                                    </a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </mat-card>

        </div>
    </div>

    <!-- FOOTER  -->
    <app-footer></app-footer>

</section>

<!-- E-MAIL code -->
<ng-template #emailDialog class="dialog">

    <mat-dialog-content>
        <form [formGroup]="emailFormGroup">

            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" placeholder="yourname@company.com"
                    (keyup)='checkEmailPattern($event)'>

                <mat-error class="mt-1" *ngIf="emailFormGroup.controls.email.hasError('pattern')">
                    Enter a valid Email Id
                </mat-error>
                <mat-error class="mt-1" *ngIf="emailFormGroup.controls.email.hasError('required')">
                    Email Id is required
                </mat-error>

                <mat-error class="mt-1" *ngIf="emailFormGroup.controls.email.hasError('notMatched')">
                    Enter Professional Email Id
                </mat-error>
            </mat-form-field>


            <div>
                <button mat-flat-button mat-dialog-close class="seconizeButton blue mt-1"
                    [disabled]='emailFormGroup.invalid' (click)="sendEmail()">
                    Send
                </button>
            </div>
            <div>
                <mat-checkbox formControlName="receiveEmail" class="full-width mt-1">
                    I Agree to receive occasional emails from Seconize.
                </mat-checkbox>
            </div>
            <div>
                <mat-checkbox formControlName="privacy_policy" class="full-width">I have reviewed <a
                        href="https://riskscore.info/termsandconditions" target="_blank" color="primary">Terms of
                        Use</a> and <a href="https://seconize.co/privacy-policy/" target="_blank"
                        color="primary">Privacy Policy.</a>
                </mat-checkbox>
            </div>
        </form>
    </mat-dialog-content>
</ng-template>