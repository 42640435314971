<section fxLayout="column" class="page-container">

    <!-- HEADER -->
    <app-header [pageName]="'How It Works'"></app-header>

    <!-- CONTENT  -->
    <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="content-container">
        <div fxLayout="column" fxLayoutAlign="stretch center" class="content-wrapper"
            [ngClass]="{'w-100':mobileQuery.matches,'w-75':!mobileQuery.matches}">
            <div fxFlex fxLayout="column" fxLayoutAlign=" center" class="content">

                <mat-card class="mat-elevation-z0  w-80" style="text-align: justify;padding: 10px !important;">
                    <h1>SCORES - How It Works</h1>


                    <h3 class="cardTitle nomargin">Summary</h3>
                    <p>
                        Contemporary vulnerability scoring standards like CVSS and EPSS lack ability to be
                        contextualized for a
                        given organization. They do not consider contextual parameters of the IT asset on which
                        vulnerability is
                        identified or the nature of organization's business. Any remediation efforts that rely on these
                        standardized scores alone may be counter-productive to overall risk reduction.

                    </p>

                    <p>
                        Seconize Contextual Risk Enumeration System (SCORES) is a free risk scoring tool for
                        vulnerabilities.
                        You can create
                        contextualized risk scores for vulnerabilities based on your organization and Asset context
                        using proven
                        decision science techniques.

                    </p>

                    <!-- Background -->
                    <h3 class="cardTitle nomargin">Background</h3>
                    <p>Terms such as vulnerability, threat, risk, are used interchangeably, leading to confusion. Below
                        are
                        definitions of terms used in the context of SCORES.
                    </p>
                    <h3 class="cardTitle nomargin small">Definitions</h3>
                    <div class="overflowauto">
                        <table mat-table [dataSource]="summaryList" class="mat-elevation-z2 mb-1">

                            <!-- Term Column -->
                            <ng-container matColumnDef="term">
                                <th mat-header-cell *matHeaderCellDef class="center" style="width: 12rem !important">
                                    Term
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{'addBorder': element.hasBorder }">
                                    {{element.term}} </td>
                            </ng-container>

                            <!-- Definition Column -->
                            <ng-container matColumnDef="definition">
                                <th mat-header-cell *matHeaderCellDef class="center"> Definition </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{'addBorder': element.hasBorder }">
                                    {{element.definition}} </td>
                            </ng-container>

                            <!-- Examples Column -->
                            <ng-container matColumnDef="examples">
                                <th mat-header-cell *matHeaderCellDef class="center"> Examples </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{'addBorder': element.hasBorder }">
                                    {{element.examples}} </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                    <!-- Risk Factors -->
                    <h3 class="cardTitle nomargin small">Risk Factors </h3>
                    <p>Efforts like patching servers, fixing software bugs, implementing policies for remediating
                        vulnerabilities identified are often resource intensive. Considering raw vulnerability score
                        alone
                        (without considering the Asset context or organization context) is not really an optimum way of
                        prioritizing the vulnerabilities. In fact, research has shown that it is counter-productive in
                        managing
                        the risk <a
                            href="https://resources.sei.cmu.edu/asset_files/WhitePaper/2018_019_001_538372.pdf">[ 1
                            ]</a>, <a href="https://www.youtube.com/watch?v=_laE5mp1Nl8">[ 2 ]</a> . Additional context
                        is
                        needed
                        to prioritize.
                    </p>
                    <ol>
                        <li>
                            <p> Threat Context: A vulnerability is threat only when there is an exploit available.
                                Further the
                                likelihood of threat increases when there is an active malware campaign exploiting this
                                vulnerability.
                            </p>
                        </li>
                        <li>
                            <p> Organization Context: Depending upon the Industry Type and Geographies organization
                                operate, the
                                likelihood of a threat occurrence due to existing vulnerabilities. This is due to nature
                                of how
                                threat actors operate and their motivations. For example, a malware exploiting
                                vulnerability in
                                SWIFT network will likely impact Banking organizations
                            </p>
                        </li>
                        <li>
                            <p> Asset Context: Not all assets are equally prone to threats. It depends on what kind of
                                controls exist. For example, a Windows Server behind a VPN is less likely to be
                                exploited than a
                                public Internet facing one. Also, not all assets are equally important, if threat event
                                occurs
                                the impact of such threat depends on how important is that asset. For example, SQL
                                Injection
                                vulnerability on an e-commerce website doing commercial transactions could be
                                devastating whereas same vulnerability on a read-only blog will have lesser impact.
                            </p>
                        </li>
                    </ol>
                    <p>A risk scoring mechanism should consider the above contextual risk factors.
                    </p>


                    <h3 class="cardTitle nomargin small">CVSS Score</h3>
                    <p>
                        <a href="https://www.first.org/cvss/" target="_blank">
                            Common Vulnerability Scoring System(CVSS)
                        </a>
                        is an open framework for communicating the characteristics and
                        severity of software vulnerabilities. CVSS consists of three metric groups: Base, Temporal, and
                        Environmental. The CVSS v3.1 Specification states that the CVSS Base Score represents only the
                        intrinsic characteristics of a vulnerability which are constant over time and across user
                        environments. So, at most CVSS score is a vulnerability score but not a risk score based on
                        definitions set above. As mere
                        presence of a vulnerability does not mean risk.
                    </p>

                    <h3 class="cardTitle nomargin small">EPSS Score</h3>
                    <p class="mb-2">
                        <a href="https://www.first.org/epss/" target="_blank">
                            Exploit Prediction Scoring System(EPSS)
                        </a>
                        is an open, data-driven effort for estimating the likelihood (probability) that a software
                        vulnerability will be exploited in the wild. This score may at best determine the future
                        likelihood of a threat occurrence and still does not consider asset and organization context for
                        qualifying as a risk score.
                    </p>



                    <h3 class="cardTitle nomargin mt-1">SCORES</h3>
                    <p>Seconize Contextual Risk Enumeration System (SCORES) is a free risk scoring tool. You can create
                        contextualized risk scores for vulnerabilities based on your organization and Asset context
                        using proven
                        decision science techniques. SCORES helps you to contextualize vulnerabilities to your
                        environment.

                    </p>
                    <p>By large the spirit of SCORES is to provide a simple, scalable way for computing contextualized
                        risk
                        score for vulnerabilities.

                    </p>


                    <div fxLayoutAlign="center" class="mt-1 mb-2">
                        <table mat-table [dataSource]="scoresList" class="mat-elevation-z2 left">

                            <!-- Context Column -->
                            <ng-container matColumnDef="context">
                                <th mat-header-cell *matHeaderCellDef style="width: 10rem !important"> Context </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{'addBorder': element.hasBorder }">
                                    {{element.context}} </td>
                            </ng-container>

                            <!-- CVSS Column -->
                            <ng-container matColumnDef="cvss">
                                <th mat-header-cell *matHeaderCellDef class="center" style="width: 5rem !important">
                                    CVSS<br>(Base Score) </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{'addBorder': element.hasBorder }"
                                    class="center">
                                    <mat-icon color="primary">

                                        {{element.cvss}}
                                    </mat-icon>
                                </td>
                            </ng-container>

                            <!-- EPSS Column -->
                            <ng-container matColumnDef="epss">
                                <th mat-header-cell *matHeaderCellDef class="center" style="width: 5rem !important">
                                    EPSS </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{'addBorder': element.hasBorder }"
                                    class="center">
                                    <mat-icon color="primary">

                                        {{element.epss}}
                                    </mat-icon>
                                </td>
                            </ng-container>

                            <!-- SCORES Column -->
                            <ng-container matColumnDef="scores">
                                <th mat-header-cell *matHeaderCellDef class="center" style="width: 5rem !important">
                                    SCORES
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{'addBorder': element.hasBorder }"
                                    class="center">
                                    <mat-icon color="primary">

                                        {{element.scores}}
                                    </mat-icon>
                                </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedScoresColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedScoresColumns;"></tr>
                        </table>
                    </div>


                    <h3 class="cardTitle nomargin mt-2">How To SCORE? </h3>
                    <p>You can SCORE vulnerabilities and create a personalized risk report for free in three simple
                        steps </p>

                    <h3 class="cardTitle nomargin small">Step 1: Select Vulnerability </h3>
                    <p>Input the CVE of interest to create a report. By default, SCORES backend is integrated with many
                        threat-intel sources to determine whether an exploit is available and active malware campaigns
                        exist. In
                        case you would like to override the default values you are free to do so. </p>

                    <h3 class="cardTitle nomargin small">Step 2: Select Asset Context </h3>
                    <p>For a given CVE, SCORES tool provides the impacted products and their respective versions by
                        default.
                        Select the appropriate product and versions based on the Assets in your environment. </p>
                    <p>Additionally, below are important contextual parameters that influence the risk score </p>
                    <ul>
                        <li> Asset Reachability: If the IT Asset is facing the Internet, then select “External”. If the
                            Asset is
                            inside a private network, for example behind a VPN or WAF then select “Internal”. </li>
                        <li> Data Classification and Rating: Provide details of what type of data the Asset contains and
                            how
                            important is that data on a simple scale of 1 to 5 (where 5 being very important). </li>
                        <li> Service Classification and Rating: Provide details of what type of service the Asset offers
                            and how
                            important is that service on a simple scale of 1 to 5 (where 5 being very important). </li>
                    </ul>
                    <h3 class="cardTitle nomargin small">Step 3: Select organization Context </h3>
                    <p class="mb-2">Threat actors often target specific industry types and geographies. Select your
                        Industry
                        type and
                        Geography(s) in which your organization operates. SCORES tool matches them with threat
                        intelligence
                        tools to contextualize the risk score accordingly. </p>


                    <h3 class="cardTitle nomargin mt-1">Next Steps </h3>
                    <p>SCORES is a free risk scoring tool that considers context of vulnerability, Asset and
                        organization to
                        compute
                        customized risk score. SCORES helps you to contextualize vulnerabilities to your environment, so
                        keep
                        calm
                        and <a href="/">SCORE now!</a> </p>
                    <!-- <p>Note: The reports once generated on this tool are static. In practice, the risk score is dynamic
                        in
                        nature
                        because the threat landscape evolves continuously and asset profile changes. In case you want to
                        monitor
                        the
                        risk score for the vulnerabilities on a continuous basis , feel free to reach us for a <a
                            href="https://seconize.co/demo/">demo</a>. </p> -->

                </mat-card>
            </div>
        </div>
    </div>

    <!-- FOOTER  -->
    <app-footer></app-footer>

</section>