import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],
})
export class HowItWorksComponent implements OnInit {
  mobileQuery: MediaQueryList;
  smallMobileQuery: MediaQueryList;
  smallerMobileQuery: MediaQueryList;
  handsetQuery: MediaQueryList;
  smallestMobileQuery: MediaQueryList
  constructor(
    private breakpointObserver: BreakpointObserver,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    //handset
    this.handsetQuery = media.matchMedia('(max-width: 1100px)');
    this._handsetQueryListener = () => changeDetectorRef.detectChanges();
    this.handsetQuery.addListener(this._handsetQueryListener);
    //mobile
    this.mobileQuery = media.matchMedia('(max-width: 900px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    //small screen mobile
    this.smallMobileQuery = media.matchMedia('(max-width: 799px)');

    this._smallMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallMobileQuery.addListener(this._smallMobileQueryListener);
    //small screen mobile
    this.smallerMobileQuery = media.matchMedia('(max-width: 590px)');
    this._smallerMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallerMobileQuery.addListener(this._smallerMobileQueryListener);
    //smallest screen mobile
    this.smallestMobileQuery = media.matchMedia('(max-width: 480px)');
    this._smallestMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallestMobileQuery.addListener(this._smallestMobileQueryListener);
  }
  private _mobileQueryListener: () => void;
  private _smallMobileQueryListener: () => void;
  private _smallerMobileQueryListener: () => void;
  private _handsetQueryListener: () => void;
  private _smallestMobileQueryListener: () => void;
  ngOnInit(): void { }
  displayedColumns: string[] = ['term', 'definition', 'examples'];
  summaryList = [
    {
      term: 'Vulnerability',
      definition:
        'A vulnerability is a weakness in an information system, system security procedures, internal controls, configurations, or implementation of IT infrastructure. ',
      examples:
        'Lack of Multifactor Authentication, Lack of Encryption, Excessive Privileges, SQL Injection, log4j, HeartBleed, Follina, CVE-2022-27664 ',
    },
    {
      term: 'Impact',
      definition:
        'An impact on organization in terms of financial, reputational losses ',
      examples:
        'Loss of Productivity, Revenue Loss, Cost of investigations, Penalties, Loss of Brand Reputation ',
    },
    {
      term: 'Threat',
      definition:
        'A threat is any circumstance or event with the potential to adversely impact organizational operations, assets, individuals through an information system. ',
      examples:
        'Denial of Service, Ransomware, Phishing, Data Breach, Espionage, Outage, Theft ',
    },
    {
      term: 'Threat Actor',
      definition:
        'A threat actor is an individual or group that can manifest a threat to the organization. ',
      examples:
        'Disgruntled Employee, Cyber Criminal, Competitor, Nation State Actor, Vendor ',
    },
    {
      term: 'Risk',
      definition:
        'A risk is a function of the likelihood of a threat event’s occurrence, by a threat actor, resulting in adverse impact ',
      examples:
        'High likelihood of a cybercriminal exploiting a SQL Injection on a website resulting in Loss of Data  ',
    },
    {
      term: 'Risk Intelligence',
      definition:
        'A collection of information that enlists potential risks to the organization  ',
      examples: 'A prioritized list of risks identified during an assessment. ',
    },
    {
      term: 'Control',
      definition:
        'A technical or non-technical information security control that remediates or mitigates a potential IT risk to the organization ',
      examples:
        'Anti-Virus, Firewall, SIEM, Information Security Policies, Security Awareness Trainings ',
      hasBorder: true,
    },
  ];

  displayedScoresColumns: string[] = ['context', 'cvss', 'epss', 'scores'];
  scoresList = [
    {
      context: 'Vulnerability',
      cvss: 'check_circle',
      epss: 'check_circle',
      scores: 'check_circle',
    },
    {
      context: 'Exploit',
      cvss: '',
      epss: 'check_circle',
      scores: 'check_circle',
    },
    {
      context: 'Active Attack Campaigns',
      cvss: '',
      epss: '',
      scores: 'check_circle',
    },
    {
      context: 'Asset Reachability ',
      cvss: '',
      epss: '',
      scores: 'check_circle',
    },
    { context: 'Asset Rank ', cvss: '', epss: '', scores: 'check_circle' },
    {
      context: 'Organization Profile  ',
      cvss: '',
      epss: '',
      scores: 'check_circle',
      hasBorder: true,
    },
  ];

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.smallMobileQuery.removeListener(this._smallMobileQueryListener);
    this.smallerMobileQuery.removeListener(this._smallerMobileQueryListener);
    this.smallestMobileQuery.removeListener(this._smallerMobileQueryListener);
    this.handsetQuery.removeListener(this._handsetQueryListener);
  }
}
