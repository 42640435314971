import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  mobileQuery: MediaQueryList;
  smallestMobileQuery: MediaQueryList

  constructor(media: MediaMatcher, changeDetectorRef: ChangeDetectorRef) {
    //mobile
    this.mobileQuery = media.matchMedia('(max-width: 900px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    //smallest screen mobile
    this.smallestMobileQuery = media.matchMedia('(max-width: 480px)');
    this._smallestMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallestMobileQuery.addListener(this._smallestMobileQueryListener);
  }

  private _mobileQueryListener: () => void;
  private _smallestMobileQueryListener: () => void;

  ngOnInit(): void {
  }

  currentYear: any = new Date().getFullYear();

}
