import {
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import {
  Component,
  Inject,
  Injector,
  OnInit,
  PLATFORM_ID,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  ComponentRef
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from '../report/report.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  // isHandset$: Observable<boolean> = this.breakpointObserver
  //   .observe(Breakpoints.Handset)
  //   .pipe(
  //     map((result) => {
  //       if (result.matches) this.mobileView = true;
  //       console.log(result.matches);

  //       return result.matches;
  //     }),
  //     shareReplay()
  //   );
  // mobileView: boolean = false;

  mobileQuery: MediaQueryList;
  smallMobileQuery: MediaQueryList
  smallerMobileQuery: MediaQueryList
  handsetQuery: MediaQueryList
  smallestMobileQuery: MediaQueryList

  cveArray: CVE[] = [];
  recentFiveVulns: any
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public dialog: MatDialog,
    public snackbar: MatSnackBar,
    public reportService: ReportService,
    private metaTagService: Meta,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object,
    private injector: Injector,
    private http: HttpClient,
    private router: Router
  ) {
    //handset

    this.handsetQuery = media.matchMedia('(max-width: 1100px)');
    this._handsetQueryListener = () => changeDetectorRef.detectChanges();
    this.handsetQuery.addListener(this._handsetQueryListener);
    //mobile
    this.mobileQuery = media.matchMedia('(max-width: 900px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    //small screen mobile
    this.smallMobileQuery = media.matchMedia('(max-width: 799px)');

    this._smallMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallMobileQuery.addListener(this._smallMobileQueryListener);
    //small screen mobile
    this.smallerMobileQuery = media.matchMedia('(max-width: 590px)');
    this._smallerMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallerMobileQuery.addListener(this._smallerMobileQueryListener);
    //smallest screen mobile
    this.smallestMobileQuery = media.matchMedia('(max-width: 480px)');
    this._smallestMobileQueryListener = () => changeDetectorRef.detectChanges();
    this.smallestMobileQuery.addListener(this._smallestMobileQueryListener);



    this.http.get('assets/kev.csv', { responseType: 'text' }).subscribe((data: any) => {
      let csvToRowArray = data.split("\n");

      for (let index = 1; index < csvToRowArray.length - 1; index++) {
        let row = csvToRowArray[index].split(",");
        this.cveArray.push(new CVE(row[0], row[1], row[2], row[3], row[4], row[5], row[6], row[7], row[8]))
      }

      this.recentFiveVulns = this.cveArray.reverse().slice(0, 5)
    });


  }


  // @ViewChild('iframe', { static: false }) 'iframe': ElementRef;
  // firstInput = 5;

  // isloaded: boolean = false

  // onLoad(iframe: any) {
  //   if (this.isloaded) {
  //     console.log('submitted the form');
  //   }
  //   this.isloaded = true
  //   console.log('iframe', iframe.src);
  // }

  // test(iframe: any) {
  //   console.log('clicked');
  //   // console.log(iframe.contentWindow.document.body.innerHTML);
  //   let doc = iframe.contentDocument || iframe.contentWindow;
  //   let inputEl = doc.getElementsByClassName('tempContDiv');
  //   console.log('inputEl', inputEl);

  // }


  private _mobileQueryListener: () => void;
  private _smallMobileQueryListener: () => void;
  private _smallerMobileQueryListener: () => void;
  private _handsetQueryListener: () => void;
  private _smallestMobileQueryListener: () => void;
  ngOnInit(): void {
    this.recentFiveVulns = this.cveArray.reverse().splice(0, 4)
    this.metaTagService.addTags([
      {
        name: 'keywords',
        content:
          'cvss, cve, epss, riskscore, vulnerability, knowyourcverisk,,KnowYourSCORE,seconize',
      },
      {
        property: 'og:description',
        content: 'A free cyber risk scoring tool for vulnerabilities',
      },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Seconize' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      // { name: 'date', content: '2021-05-17', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' },
    ]);

    if (this.activatedRoute?.snapshot?.paramMap?.get('id')) {
      this.cveID.setValue(this.activatedRoute.snapshot.paramMap.get('id'));
      this.getCVEdata();
    }

    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('cveID')) {
        this.cveID.setValue(localStorage.getItem('cveID'));
        this.getCVEdata();
        localStorage.clear();
      }
    }
  }

  cveData: any = '';
  cveRegex: string = '^CVE-[0-9]{4}-[0-9]{4,7}$';
  cveID = new FormControl('', [
    Validators.required,
    Validators.pattern(this.cveRegex),
  ]);
  isLoading: boolean = true;
  riskScore: number = 0;
  showStepper: boolean = false;

  isCVE_data_Loading: boolean = false;
  nodata: boolean = false;
  invalidCVE: boolean = false;
  getCVEdata() {
    this.nodata = false;
    this.invalidCVE = false;
    this.cveID.setValue(this.cveID.value.toUpperCase().trim());

    if (this.cveID.invalid) {
      this.invalidCVE = true;
      return;
    }

    this.isCVE_data_Loading = true;
    this.reportService.getCVEfromSRI(this.cveID.value).subscribe((data: any) => {
      this.isCVE_data_Loading = false;

      if (data.success && data.data.vtstats != null) {
        this.cveData = data.data.vtstats?.cves[0];

        this.cveData.referenceUrls = this.cveData?.referenceUrls?.split(',');

        this.exploitCodeExists.setValue(this.cveData.exploitCodeExists);
        this.malwareCampaignActive.setValue(this.cveData.malwareCampaignActive);

        this.staticCveID = this.cveID.value;

        this.resetStepper();
        this.getCPE();

      } else {
        this.nodata = true;
        this.prev_cve_id = this.cveID.value;
      }
    });
  }
  prev_cve_id: any;
  checkCVEId(event: any) {
    this.invalidCVE = false;

    if (event.target.value == this.prev_cve_id) {
      this.nodata = true;
    } else {
      this.nodata = false;
    }
  }

  resetStepper() {
    this.showRiskTab = false;
    this.showStepper = true;
    this.organizationFormGroup.reset();
    this.assetReachability.setValue('EXTERNAL');
    this.dataRank = 0;
    this.serviceRank = 0;
    this.impact = 0;
    this.vendor.reset();
    this.product.reset();
    this.version.reset();
  }

  enabledButton: boolean = true;
  enableStep2() {
    // if (!this.version.value) {
    //   this.enabledButton = true
    //   return true
    // }
    // else if (this.version.value)
    if (
      this.dataClass.value &&
      this.dataRank &&
      this.serviceClass.value &&
      this.serviceRank &&
      this.version.value &&
      this.product.value &&
      this.vendor.value
    ) {
      this.enabledButton = true;
      return true;
    }
    this.enabledButton = false;
    return false;
  }

  getCPE() {
    this.reportService.getCPE(this.cveID.value).subscribe((data: any) => {
      if (data.success) {
        this.cpeList = data.data.vtstats.cpes;

        // create Map of vendor -> product -> version
        this.cpeMap = this.cpeList?.reduce((acc: any, current: any) => {
          acc[current.vendor] = acc[current.vendor] || {};
          acc[current.vendor][current.product] = acc[current.vendor][current.product] || {};
          acc[current.vendor][current.product][current.version] = null;
          return acc;
        }, {});
      } else {
        this.snackbar.open('Something went wrong!', '', {
          duration: 1500,
          horizontalPosition: 'end',
          verticalPosition: 'top',
          panelClass: ['error-snackbar']
        });
      }

      if (Object.keys(this.cpeMap).length == 1) {
        this.vendor.setValue(Object.keys(this.cpeMap)[0]);

        if (Object.keys(this.cpeMap?.[Object.keys(this.cpeMap)[0]]).length == 1) {
          this.product.setValue(Object.keys(this.cpeMap?.[Object.keys(this.cpeMap)[0]])[0])
        }
      }

      if (Object.keys(this.cpeMap?.[Object.keys(this.cpeMap)[0]]).length == 1) {
        this.vendor.setValue(Object.keys(this.cpeMap)[0]);
        this.product.setValue(Object.keys(this.cpeMap?.[Object.keys(this.cpeMap)[0]])[0])
      }

    });
  }

  getItem(item: any) {
    return item.toString();
  }

  vendorChange(vendor: any) {

    if (Object.keys(this.cpeMap?.[vendor]).length == 1) {
      this.product.setValue(Object.keys(this.cpeMap?.[vendor])[0])
    }

  }

  ////////////////////////////// STEP 1 //////////////////////////////////////
  exploitCodeExists = new FormControl(null);
  malwareCampaignActive = new FormControl(null);
  ////////////////////////////// STEP 1  END //////////////////////////////////////

  ////////////////////////////// STEP 2 //////////////////////////////////////
  vendor = new FormControl('');
  product = new FormControl('');
  version = new FormControl('');
  notApplicable = new FormControl(false);
  cpeList: any = [];
  cpeMap: any = {};

  assetReachability = new FormControl('EXTERNAL');
  dataClass = new FormControl('');
  serviceClass = new FormControl('');
  dataRank: number = 0;
  serviceRank: number = 0;
  impact: number = 0;

  dataAssetProfiles = [
    { name: 'Employee Data', rating: '0', value: 'NONE' },
    { name: 'Intellectual Property', rating: '0', value: 'NONE' },
    { name: 'Confidential Data', rating: '0', value: 'NONE' },
    { name: 'Customer Data', rating: '0', value: 'NONE' },
    { name: 'Financial Information', rating: '0', value: 'NONE' },
  ];
  serviceAssetProfiles = [
    { name: 'Customer Services', rating: '0', value: 'NONE' },
    { name: 'Internal Operations', rating: '0', value: 'NONE' },
  ];

  step = 5;
  max = 100;
  min = 0;
  tickInterval = 5;

  // data
  employeeObj: any = {
    id: '',
    name: 'Employee Data',
    rating: '0',
    value: 'NONE',
  };

  // service
  customerServiceObj: any = {
    id: '',
    name: 'Customer Services',
    rating: '0',
    value: 'NONE',
  };

  assetProfileForm = this.fb.group({
    dataClassification: new FormArray([this.fb.group(this.employeeObj)]),
    serviceClassification: new FormArray([
      this.fb.group(this.customerServiceObj),
    ]),
  });

  serviceClassification(): FormArray {
    return this.assetProfileForm.get('serviceClassification') as FormArray;
  }
  dataClassification(): FormArray {
    return this.assetProfileForm.get('dataClassification') as FormArray;
  }
  stars: any = [
    // { name: 'NONE', value: '0' },
    { value: 'VERY_LOW', rating: '1' },
    { value: 'LOW', rating: '2' },
    { value: 'MEDIUM', rating: '3' },
    { value: 'HIGH', rating: '4' },
    { value: 'CRITICAL', rating: '5' },
  ];
  //profile stars tooltips
  tooltipData: any = [
    'Asset contains negligible data records. Unauthorized access of such data has no impact.',
    'Asset contains lesser data records. Unauthorized access of such data has low impact.',
    'Asset contains moderate number of data records. Unauthorized access of such data has medium impact.',
    'Asset contains high amount of data records. Unauthorized access of such data has severe impact.',
    'Asset contains very high amount of data records. Unauthorized access of such data has catastrophic impact.',
  ];
  //---Changing stars color based on click---////
  profileStars1(name: any, i: any, data: any) {
    // data
    if (name === 'Employee Data') {
      if (
        this.assetProfileForm.controls.dataClassification.value[i].rating ==
        1 &&
        data.rating == 1
      ) {
        this.assetProfileForm.controls.dataClassification.value[i].rating = 0;
        this.assetProfileForm.controls.dataClassification.value[i].value =
          'NONE';
      } else {
        this.assetProfileForm.controls.dataClassification.value[i].rating =
          data.rating;
        this.assetProfileForm.controls.dataClassification.value[i].value =
          data.value;
      }
    }
    if (name === 'Intellectual Property') {
      if (
        this.assetProfileForm.controls.dataClassification.value[i].rating ==
        1 &&
        data.rating == 1
      ) {
        this.assetProfileForm.controls.dataClassification.value[i].rating = 0;
        this.assetProfileForm.controls.dataClassification.value[i].value =
          'NONE';
      } else {
        this.assetProfileForm.controls.dataClassification.value[i].rating =
          data.rating;
        this.assetProfileForm.controls.dataClassification.value[i].value =
          data.value;
      }
    }

    // service
    if (name === 'Customer Services') {
      if (
        this.assetProfileForm.controls.serviceClassification.value[i].rating ==
        1 &&
        data.rating == 1
      ) {
        this.assetProfileForm.controls.serviceClassification.value[
          i
        ].rating = 0;
        this.assetProfileForm.controls.serviceClassification.value[i].value =
          'NONE';
      } else {
        this.assetProfileForm.controls.serviceClassification.value[i].rating =
          data.rating;
        this.assetProfileForm.controls.serviceClassification.value[i].value =
          data.value;
      }
    }
    if (name === 'Internal Operations') {
      if (
        this.assetProfileForm.controls.serviceClassification.value[i].rating ==
        1 &&
        data.rating == 1
      ) {
        this.assetProfileForm.controls.serviceClassification.value[
          i
        ].rating = 0;
        this.assetProfileForm.controls.serviceClassification.value[i].value =
          'NONE';
      } else {
        this.assetProfileForm.controls.serviceClassification.value[i].rating =
          data.rating;
        this.assetProfileForm.controls.serviceClassification.value[i].value =
          data.value;
      }
    }

    if (name == 'Employee Data') {
      this.dataRank = data.rating;
    }
    if (name == 'Customer Services') {
      this.serviceRank = data.rating;
    }

    var rank = Math.max(this.dataRank, this.serviceRank);
    if (rank == 0) {
      this.impact = 0;
    } else if (rank == 1) {
      this.impact = 40;
    } else if (rank == 2) {
      this.impact = 60;
    } else if (rank == 3) {
      this.impact = 80;
    } else if (rank == 4) {
      this.impact = 90;
    } else if (rank == 5) {
      this.impact = 100;
    }
  }
  ////////////////////////////// STEP 2  END //////////////////////////////////////

  ////////////////////////////// STEP 3 //////////////////////////////////////
  industryList = this.reportService.industryType();
  countryList = this.reportService.countriesList();
  ////////////////////////////// STEP 3  END //////////////////////////////////////

  /////////////////////////////// GENERATE SCORE ///////////////////////////////
  showRiskTab: boolean = false;
  url: string = '';
  reportUrl: string = '';
  staticCveID: any = '';
  getScore() {
    var payload = {
      id: '',
      cveId: this.staticCveID,
      szeId: '',
      name: '',
      assetType: 'WEBSITE',
      assetReachability: this.assetReachability.value,
      industryMatching: null,
      countryMatching: null,
      cvssv2Score: this.cveData.cvssV2Score,
      cvssv3Score: this.cveData.cvssV3Score,
      malwareActive: this.malwareCampaignActive.value,
      malwareReported: '',
      vulnType: '',
      exploitCodeExists: this.exploitCodeExists.value,
      severity: '',
      vulnScore: 0,
      threatScore: 0,
      impactScore: this.impact,
      riskScore: 0,
      epss: null,
      industry: this.organizationFormGroup.controls.industry.value,
      geography: this.organizationFormGroup.controls.country.value.join(','),
      malwareName: null,
      vendor: this.vendor.value ? this.vendor.value : 'NA',
      product: this.product.value ? this.product.value : 'NA',
      version: this.version.value ? this.version.value : 'NA',
      dataProfile: this.dataClass.value,
      dataRating: this.dataRank,
      serviceProfile: this.serviceClass.value,
      serviceRating: this.serviceRank,
      confidentialityRating: this.dataRank,
      integrityRating: this.dataRank,
      availabilityRating: this.serviceRank,
      assetRank: this.impact,
    };
    this.isLoading = true;
    this.reportService.getScore(payload).subscribe((data: any) => {
      this.isLoading = false;
      if (data.success) {
        this.riskScore = +data.data;
        this.showRiskTab = true;
        this.reportService.createScore(payload).subscribe((data: any) => {
          if (data.success) {
            if (isPlatformServer(this.platformId)) {
              console.log(this.injector.get('host'));
            } else {
              this.url = document.location.href + 'cve/' + this.cveID.value + '/' + data.data;
              this.reportUrl = 'cve/' + this.cveID.value + '/' + data.data;
              this.reportID.setValue(this.url.split('/').reverse()[0])
            }
            this.sendEmail()
          }
        });
      }
    });
  }

  copyToast() {
    this.snackbar.open('Shareable Link Copied!', '', {
      duration: 1500,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: ['copy-snackbar']
    });
  }

  /////////////////////////////// GENERATE SCORE - END ///////////////////////////////

  CVE_selected(CVE_ID: any) {
    this.cveID.setValue(CVE_ID.replaceAll('"', ''));
    this.getCVEdata();
  }

  reset_CVE_ID() {
    // this.cveID.setValue('');
    this.cveID.setValue(this.cveID.value.toUpperCase().trim());
    this.resetStepper();
    this.showStepper = false;
  }

  tooltip(date: any, vendor: any) {
    return date.replaceAll('"', '') + '  (' + vendor.replaceAll('"', '') + ')'
  }

  organizationFormGroup = this.fb.group({
    industry: ['', Validators.required],
    country: ['', Validators.required],
  });

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.smallMobileQuery.removeListener(this._smallMobileQueryListener);
    this.smallerMobileQuery.removeListener(this._smallerMobileQueryListener);
    this.smallestMobileQuery.removeListener(this._smallerMobileQueryListener);
    this.handsetQuery.removeListener(this._handsetQueryListener);

  }
  emailFormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,7}$")]],
    receiveEmail: [true, [Validators.requiredTrue]],
    privacy_policy: [true, [Validators.requiredTrue]]
  });

  reportID = new FormControl('', [Validators.required])

  // showLoader: boolean = false
  sendEmail() {

    if (this.reportID.invalid) {
      return
    }

    let body = {
      "searchMap": {
        "ID": this.reportID.value,
        "EMAIL_ID": this.emailFormGroup.controls.email.value
      }
    }

    // this.showLoader = true
    this.reportService.sendEmail(body).subscribe((data: any) => {
      // this.showLoader = false
      if (data.success) {
        this.snackbar.open('Success', '', {
          duration: 1500,
          horizontalPosition: 'end',
          verticalPosition: 'top',
          panelClass: ['success-snackbar']
        });
        // this.router.navigateByUrl(this.reportUrl);
      } else {
        this.snackbar.open('Something went wrong!', '', {
          duration: 1500,
          horizontalPosition: 'end',
          verticalPosition: 'top',
          panelClass: ['error-snackbar']
        });
      }

    })

  }

  viewFullReport() {
    this.router.navigateByUrl(this.reportUrl);
  }

  unProfessionalEmailDomainsList: any = []

  getUnProfessionalEmailDomains() {
    this.http.get('https://gist.githubusercontent.com/ammarshah/f5c2624d767f91a7cbdc4e54db8dd0bf/raw/660fd949eba09c0b86574d9d3aa0f2137161fc7c/all_email_provider_domains.txt', { responseType: 'text' }).subscribe((data: any) => {
      this.unProfessionalEmailDomainsList = data.split('\n')
    });
  }

  checkEmailPattern(event: any) {
    if (!!~event.target.value?.indexOf("@")) {
      if (this.unProfessionalEmailDomainsList.includes(event.target.value?.split('@')[1])) {
        this.emailFormGroup.controls.email.setErrors({
          notMatched: true
        })
      }
    }
  }


  //   app.controller('FormController', function($scope,$http){
  //     $http.get("data/order.json").then(
  //        function success(response) {
  //          // please note that as this is asynchronous, 
  //          // the $scope.schema will be available after response
  //          // has been received but this should be ok
  //          $scope.schema = angular.fromJson(response.data);
  //        },
  //        function error(response) { /* handle error */ });
  //     // do other stuff
  //  });
}

// TWITTER
// <meta name="twitter:creator" content = "@seconize" />
// <meta name="twitter:title" content="Seconize Contextual Risk Enumeration System (SCORES)">
// <meta name="twitter:description" content="Seconize Contextual Risk Enumeration System (SCORES)">
// <meta name="twitter:url" content="https://seconize.co">
// <meta name="twitter:card" content="put gauge here">
// <meta name="twitter:image:alt" content="Seconize">

// <!--  Essential META Tags -->
// <meta property="og:title" content="Seconize Contextual Risk Enumeration System (SCORES)">
// <meta property="og:type" content="article" />
// <meta property="og:image" content="http://seconizeLogo.jpg">
// <meta property="og:url" content="https://seconize.co">

// <!--  Non-Essential, But Recommended -->
// <meta property="og:description" content="Seconize Contextual Risk Enumeration System (SCORES)">
// <meta property="og:site_name" content="Seconize">

// <!--  Non-Essential, But Required for Analytics -->
// <meta property="fb:app_id" content="your_app_id" />
// <meta name="twitter:site" content="@seconize">


export class CVE {

  cveID: any;
  vendorProject: any;
  product: any;
  lastName: any;
  vulnerabilityName: any;
  dateAdded: any;
  shortDescription: any;
  requiredAction: any;
  dueDate: any;
  notes: any;

  constructor(cveID: any, vendorProject: any, product: any, vulnerabilityName: any, dateAdded: any, shortDescription: any, requiredAction: any, dueDate: any, notes: any) {
    this.cveID = cveID;
    this.vendorProject = vendorProject;
    this.product = product;
    this.vulnerabilityName = vulnerabilityName;
    this.dateAdded = dateAdded;
    this.requiredAction = requiredAction;
    this.shortDescription = shortDescription;
    this.dueDate = dueDate;
    this.notes = notes;
  }

}