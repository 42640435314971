// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  tracking_id: 'G-3TF2Y3T2VM',
  env: "dev",

  url: "https://dev.seconize.co/api/scores",
  sriUrl: "https://sri.seconize.co/api",
  g_recaptcha_site_key: '6LcmSo0UAAAAADYu7brKotaFbhYCJhiKKZ5w54rn',
  apiKey: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxMjMsInVzZXJuYW1lIjoic3VzYW4iLCJyb2xlcyI6WyJ1c2VyIiwibW9kZXJhdG9yIl19.7wXUg7M9MTEqqNksdOU-616cIx4F-oEN0q29krzjkx8"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
