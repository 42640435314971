<section fxLayout="column" class="page-container">

    <!-- HEADER START -->
    <app-header [pageName]="'Reports'"></app-header>

    <!-- CONTENT  -->
    <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="content-container">
        <div fxLayout="column" class="content-wrapper"
            [ngClass]="{'w-100':mobileQuery.matches,'w-75':!mobileQuery.matches}">


            <div [fxLayout]="mobileQuery.matches?'column':'row'"
                [fxLayoutAlign]="mobileQuery.matches?'start start':'space-between center'" class="mt-2">

                <div fxLayoutAlign="start center" class=" ml-5">
                    Reports created by Anonymous Users
                </div>

                <!-- SEARCH BAR  -->
                <div fxLayoutAlign="end end" class="mr-5"
                    [ngClass]="{'w-100':mobileQuery.matches,'mt-10px':mobileQuery.matches}">
                    <div class="seconizeInput" fxLayoutAlign="center center">
                        <mat-icon id="searchBar" class="search" matPrefix (click)="cve_id==''?'':searchByCVE_ID()">
                            search
                        </mat-icon>
                        <input type="text" [(ngModel)]="cve_id" placeholder="Search by CVE ID"
                            (keyup.enter)="searchByCVE_ID()" (keyup)="cve_id!=''?'':searchByCVE_ID()">
                        <mat-icon [ngClass]="cve_id==''|| cve_id==null?'hidden':'visible'" class="search" matPrefix
                            (click)="cve_id='';searchByCVE_ID();">close
                        </mat-icon>
                    </div>
                </div>

            </div>

            <div fxLayout="column" fxLayoutAlign="end end"
                [class]="mobileQuery.matches?'mt-1 mb-3 w-100 table-container':'mt-1 mb-3 w-100'">

                <!-- REPORTS TABLE -->
                <div class="mat-elevation-z8 overflowauto w-90 mr-5">

                    <table *ngIf="!isLoading && dataSource.data.length" mat-table [dataSource]="dataSource" matSort
                        class="seconizeShadow w-100">

                        <ng-container matColumnDef="cve" sticky>
                            <th mat-header-cell *matHeaderCellDef>CVE&nbsp;ID</th>
                            <td mat-cell *matCellDef="let element">
                                <a [href]="getReportURL(element.cveId,element.id)">
                                    {{element.cveId}}
                                </a>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="cvss">
                            <th mat-header-cell *matHeaderCellDef>CVSS&nbsp;&nbsp;</th>
                            <td mat-cell *matCellDef="let element" class="center">{{element.cvssv3Score}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="exploit">
                            <th mat-header-cell *matHeaderCellDef class="center">Exploit&nbsp;&nbsp;</th>
                            <td mat-cell *matCellDef="let element" class="center">
                                <mat-icon color="warn">
                                    {{element.exploitCodeExists?'done':'close'}}
                                </mat-icon>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="malware">
                            <th mat-header-cell *matHeaderCellDef class="center">Malware</th>
                            <td mat-cell *matCellDef="let element" class="center">
                                <mat-icon color="warn">
                                    {{element.malwareActive?'done':'close'}}
                                </mat-icon>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="assetReachability">
                            <th mat-header-cell *matHeaderCellDef class="center">Asset Reachability</th>
                            <td mat-cell *matCellDef="let element" class="center">
                                {{ element.assetReachability?element.assetReachability:'--' }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="threatScore">
                            <th mat-header-cell *matHeaderCellDef [class]="!isLoading?'special-column':''"
                                class="center">
                                Threat Score</th>
                            <td mat-cell *matCellDef="let element" class="center special-column">{{
                                element.threatScore}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="dataRating">
                            <th mat-header-cell *matHeaderCellDef class="center">Data Rating</th>
                            <td mat-cell *matCellDef="let element" class="center">{{ element.dataRating}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="serviceRating">
                            <th mat-header-cell *matHeaderCellDef class="center">Service Rating​</th>
                            <td mat-cell *matCellDef="let element" class="center">{{ element.serviceRating}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="impactScore">
                            <th mat-header-cell *matHeaderCellDef [class]="!isLoading?'special-column':''"
                                class="center">
                                Impact Score</th>
                            <td mat-cell *matCellDef="let element" class="center special-column">
                                {{element.impactScore}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="riskScore">
                            <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header
                                (click)="sortByColumn('risk_score')" [ngClass]="{'mw-65px':mobileQuery.matches}">
                                <span class="riskScore​​">
                                    Risk Score​ <mat-icon class="arrow">{{sort?'arrow_upward':'arrow_downward'}}
                                    </mat-icon>
                                </span>
                            </th>
                            <td mat-cell *matCellDef="let element" class="center">

                                <span *ngIf="element.severity && element.riskScore>0" [ngClass]="element.severity"
                                    class="center severityButton" matTooltip="{{element.severity | titlecase}}">
                                    {{ element.severity==='CRITICAL'?'C':element.severity==='HIGH'?'H':
                                    element.severity==='MEDIUM'?'M':'L'}}
                                    - {{ element.riskScore }}
                                </span>
                                <span *ngIf="element.riskScore<=0" matTooltip="Not Allowed">
                                    NA
                                </span>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>



                <!------------ PAGINATION HERE------------->

                <div *ngIf="!isLoading && dataSource.data.length" fxLayoutAlign="start end"
                    class="mr-5 paginationFont w-90per">
                    <mat-card *ngIf="!isLoading && dataCollection.length && +totalCount > 5"
                        class="customPaginatorWrapper seconizeShadowBottom" fxLayoutAlign="space-around center"
                        fxLayout="row">

                        <small>Items per page: </small>

                        <mat-form-field appearance="outline" fxFlex=15>
                            <mat-select [formControl]=pageSize (selectionChange)="_page=1;getAllReports()">
                                <mat-option value=5>5</mat-option>
                                <mat-option value=10>10</mat-option>
                                <mat-option value=15>15</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <small *ngIf="+itemCount <= +totalCount">{{itemCount}} of {{totalCount}}</small>
                        <small *ngIf="+itemCount >= +totalCount">{{totalCount}} of {{totalCount}}</small>
                        <div>
                            <button class="buttonIcon" mat-icon-button [disabled]="+_page === +1" (click)=firstPage()>
                                <mat-icon class="paginationIcon">first_page</mat-icon>
                            </button>
                            <button class="buttonIcon" mat-icon-button [disabled]="+_page === +1" (click)="prev()">
                                <mat-icon class="paginationIcon">chevron_left</mat-icon>
                            </button>
                            <button class="buttonIcon" mat-icon-button [disabled]="+_page == +totalPages"
                                (click)="next()">
                                <mat-icon class="paginationIcon">chevron_right</mat-icon>
                            </button>
                            <button class="buttonIcon" mat-icon-button [disabled]="+_page == +totalPages"
                                (click)="lastPage()">
                                <mat-icon class="paginationIcon">last_page</mat-icon>
                            </button>
                        </div>
                    </mat-card>
                </div>

                <!------------ PAGINATION END------------->

                <div *ngIf="isLoading" class="seconizeShadowBottom w-90 mr-5 empty-rows">
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div fxLayoutAlign="center center">&nbsp;Loading...</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </div>

                <div *ngIf="!isLoading && !dataSource.data.length" class="seconizeShadowBottom w-90 mr-5 empty-rows">
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div fxLayoutAlign="center center">&nbsp;
                        <div id="emoji" fxLayout="column" fxLayoutAlign="center center">
                            <div class="error-emoji"> ┐(´•_•`)┌</div>
                            <h1>No Reports found</h1>
                        </div>
                    </div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </div>
            </div>

        </div>
    </div>

    <!-- FOOTER  -->
    <app-footer></app-footer>

</section>