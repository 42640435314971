<div fxFlex fxLayout="column" fxLayoutAlign="center center" [class]="{'text-align-center':mobileQuery.matches}">
    <div fxLayout="column" fxLayoutAlign="center center"
        [ngClass]="{'w-100 small-footer':mobileQuery.matches,'w-75 footer':!mobileQuery.matches}">
        <div fxFlex fxLayout="column" fxLayoutAlign="center center" [ngClass]="{'m-7px':smallestMobileQuery.matches}">
            <small>Copyright © 2017-{{currentYear}} Seconize. All Rights Reserved.&nbsp;<a color="primary"
                    href="/termsandconditions" routerLinkActive='isActive'>Terms&nbsp;and&nbsp;Conditions</a></small>
            <small>Seconize Technologies Private Limited is an IS027001:2013 certified company.&nbsp;<a
                    href="https://seconize.co/demo/" target="_blank" color="primary">Free&nbsp;Trial</a></small>
        </div>
    </div>
</div>